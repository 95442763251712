/*!
 * imperator-2-layout - Вёрстка для сайта охранного агентства "Император"
 * @version v2.3.1
 * @author Adorazel
 * @link https://adorazel.online
 * @license MIT
 */






html {
  font-family: sans-serif;
  
  line-height: 1.15;
  
  -ms-text-size-adjust: 100%;
  
  -webkit-text-size-adjust: 100%;
  
}


body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


figcaption,
figure,
main {
  
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  
  height: 0;
  
  overflow: visible;
  
}

pre {
  font-family: monospace, monospace;
  
  font-size: 1em;
  
}


a {
  background-color: transparent;
  
  -webkit-text-decoration-skip: objects;
  
  cursor: pointer;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  
  text-decoration: underline;
  
  text-decoration: underline dotted;
  
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  
  font-size: 1em;
  
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}


audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}


button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
input {
  
  overflow: visible;
}

button,
select {
  
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  
  color: inherit;
  
  display: table;
  
  max-width: 100%;
  
  padding: 0;
  
  white-space: normal;
  
}

progress {
  display: inline-block;
  
  vertical-align: baseline;
  
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  
  padding: 0;
  
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}


details,
menu {
  display: block;
}

summary {
  display: list-item;
}


canvas {
  display: inline-block;
}

template {
  display: none;
}


[hidden] {
  display: none!important;
}
@media (min-width: 1920px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
}
.row.narrow-row {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}
.row.narrow-row > * {
  padding-left: 7px;
  padding-right: 7px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .col-md-20 {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-lg-20 {
    width: 20%;
  }
}
.flex-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
.flex-row > * {
  float: none;
}
.flex-row .flex-item {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}
.flex-row.narrow-row {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}
.flex-row.narrow-row > * {
  padding-left: 7px;
  padding-right: 7px;
}
.flex-wrap {
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
.jcc {
  justify-content: center;
}
.jsb {
  justify-content: space-between;
}
.jcs {
  justify-content: flex-start;
}
.aic {
  align-items: center;
}
.ait {
  align-items: flex-start;
}
.aib {
  align-items: flex-end;
}
.ais {
  align-items: stretch;
}
.grid {
  margin-left: -15px;
  margin-right: -15px;
}
.grid .grid-item {
  padding-left: 15px;
  padding-right: 15px;
}
.img-wrap {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.img-wrap img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
}
.img-wrap.text-right {
  text-align: right;
}
.img-wrap.text-left {
  text-align: left;
}
.captcha-wrap img {
  height: 52px;
  width: 100%;
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  position: relative;
  pointer-events: none;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
ul:not(.list-unstyled):not(.list-inline) {
  padding: 0;
}
ul:not(.list-unstyled):not(.list-inline) > li {
  list-style: none;
  padding-left: 2.5em;
  position: relative;
  margin-bottom: 1em;
}
ul:not(.list-unstyled):not(.list-inline) > li::before {
  display: block;
  content: "";
  width: 0.6875em;
  height: 0.6875em;
  background: #ce1518;
  position: absolute;
  left: 0;
  top: 0.35em;
}
ul.cheks-list > li {
  padding-left: 45px;
  position: relative;
  margin-bottom: 0.75em;
}
ul.cheks-list > li::before {
  display: block;
  content: "";
  border-left: 2px solid #ce1518;
  border-bottom: 2px solid #ce1518;
  width: 1.15em;
  height: 0.75em;
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  top: 0;
}
ol:not(.list-unstyled):not(.list-inline) {
  padding: 0;
}
ol:not(.list-unstyled):not(.list-inline) > li {
  list-style: none;
  padding-left: 2.5em;
  position: relative;
  margin-bottom: 1em;
  counter-increment: inst;
  min-height: 3.075em;
  padding-top: 0.45em;
}
ol:not(.list-unstyled):not(.list-inline) > li::before {
  display: block;
  content: counter(inst);
  font-size: 0.66666667em;
  font-weight: 900;
  width: 2.75em;
  height: 2.75em;
  line-height: 2.85em;
  text-align: center;
  border-radius: 50%;
  background: #ce1518;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
}

@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../font/Circe-ExtraBold.woff2') format('woff2'), url('../font/Circe-ExtraBold.woff') format('woff'), url('../font/Circe-ExtraBold.ttf') format('truetype'), url('../font/Circe-ExtraBold.svg#Circe-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-Bold.eot?#iefix') format('embedded-opentype'), url('../font/Circe-Bold.woff2') format('woff2'), url('../font/Circe-Bold.woff') format('woff'), url('../font/Circe-Bold.ttf') format('truetype'), url('../font/Circe-Bold.svg#Circe-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-Light.eot?#iefix') format('embedded-opentype'), url('../font/Circe-Light.woff2') format('woff2'), url('../font/Circe-Light.woff') format('woff'), url('../font/Circe-Light.ttf') format('truetype'), url('../font/Circe-Light.svg#Circe-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../font/Circe-ExtraLight.woff2') format('woff2'), url('../font/Circe-ExtraLight.woff') format('woff'), url('../font/Circe-ExtraLight.ttf') format('truetype'), url('../font/Circe-ExtraLight.svg#Circe-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-Thin.eot?#iefix') format('embedded-opentype'), url('../font/Circe-Thin.woff2') format('woff2'), url('../font/Circe-Thin.woff') format('woff'), url('../font/Circe-Thin.ttf') format('truetype'), url('../font/Circe-Thin.svg#Circe-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circe';
  src: url('../font/Circe-Regular.eot?#iefix') format('embedded-opentype'), url('../font/Circe-Regular.woff2') format('woff2'), url('../font/Circe-Regular.woff') format('woff'), url('../font/Circe-Regular.ttf') format('truetype'), url('../font/Circe-Regular.svg#Circe-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: Circe, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #1a1a1a;
}
body * {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
optgroup,
select,
textarea {
  font-family: Circe, Arial, sans-serif;
}
a {
  color: #ce1518;
  text-decoration: underline;
}
a:hover {
  color: #b71315;
  text-decoration: none;
}
a:focus {
  color: #ce1518;
  text-decoration: underline;
}
a:active,
a:active:focus {
  color: #a01013;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}

.ttu {
  text-transform: uppercase;
}
.h1 {
  font-weight: bold;
  text-transform: uppercase;
}
.decorated-title {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 73px;
  padding-top: 0.15em;
  position: relative;
  font-size: 20px;
  min-height: 45px;
}
@media (min-width: 768px) {
  .decorated-title {
    font-size: 36px;
  }
}
.decorated-title::before {
  display: block;
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 10px solid #ce1518;
}
.decorated-title span {
  font-weight: 300;
}
.underlined-title {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  border-bottom: 1px solid #ce1518;
  padding-bottom: 10px;
  margin-top: 0;
}
.underlined-title::after {
  display: block;
  content: "";
  width: 60px;
  height: 5px;
  background: #ce1518;
  position: absolute;
  left: 0;
  bottom: -3px;
}
blockquote {
  border: 1px solid #ce1518;
  position: relative;
  padding: 20px 30px 20px 48px;
  font-size: 16px;
  margin-left: 22px;
}
@media (min-width: 768px) {
  blockquote {
    padding: 40px 70px;
  }
}
blockquote::before {
  display: block;
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border: 10px solid #ce1518;
  background: white;
}
blockquote strong {
  line-height: 1.2;
  font-size: 16px;
}
@media (min-width: 768px) {
  blockquote strong {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  blockquote strong {
    font-size: 36px;
  }
}
.rich-text-content {
  font-size: 18px;
}
.rich-text-content p {
  margin-bottom: 1.2em;
}
.rich-text-content h3 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
}
.rich-text-content h3:not(:first-child) {
  margin-top: 30px;
}
.rich-text-content ul:not(.list-unstyled):not(.list-inline) li::before {
  border-radius: 50%;
}
.rich-text-content.vacancy-mod p {
  margin-bottom: 0.7em;
}
article h3.ttu {
  margin-top: 40px;
  font-size: 14px;
}
@media (min-width: 768px) {
  article h3.ttu {
    font-size: 22px;
  }
}
.btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
}
.btn:hover {
  opacity: 0.8;
}
.btn:active {
  opacity: 1;
}
.transparent-btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}
.transparent-btn .dotted {
  font-size: 16px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
.transparent-btn .dotted::after {
  display: block;
  content: '...........................................................................................................';
  position: absolute;
  width: 100%;
  overflow: hidden;
  left: 0;
  bottom: -0.45em;
  transition: color 0.25s;
}
.transparent-btn:hover {
  text-decoration: none;
}
.transparent-btn:hover .dotted::after {
  color: transparent;
}
.common-btn {
  display: inline-block;
  padding: 7px 10px;
  position: relative;
  background-color: #b8b8b8;
  border: 1px solid #b8b8b8;
  white-space: nowrap;
  transition: box-shadow 0.25s;
  line-height: 1;
  text-decoration: none;
}
@media (min-width: 768px) {
  .common-btn {
    padding: 14px 20px;
  }
}
.common-btn .text {
  text-align: center;
  font-size: 16px;
  display: block;
  line-height: 1.4;
  pointer-events: none;
  vertical-align: middle;
  white-space: normal;
  color: white;
  text-transform: uppercase;
}
.common-btn strong {
  font-weight: bold;
}
.common-btn:hover {
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}
.common-btn:focus {
  text-decoration: none;
  box-shadow: 0 0 0 0 transparent;
}
.common-btn:active {
  box-shadow: 0 0 0 0 transparent;
  top: 1px;
}
.btn-block {
  display: block;
}
.menu-btn {
  background: #3d4045;
  padding: 6px;
  line-height: 3px;
}
.menu-btn .icon {
  font-size: 30px;
  color: white;
}
@media (min-width: 768px) {
  .menu-btn .icon {
    font-size: 38px;
  }
}
.close-btn,
.mfp-close-btn-in .mfp-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-style: normal;
  font-size: 28px;
  border-radius: 50%;
  font-family: Arial, Baskerville, monospace;
  color: #ce1518;
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 1;
  z-index: 10;
}
.close-btn:hover,
.mfp-close-btn-in .mfp-close:hover {
  background-color: white;
}
.mfp-close-btn-in .mfp-close {
  top: 0;
  right: 0;
}
@media (min-width: 768px) {
  .mfp-close-btn-in .mfp-close {
    top: 15px;
    right: 15px;
  }
}
.away-btn {
  background: white;
  border: 4px solid #e5e7ea;
  white-space: nowrap;
  padding: 4px;
}
@media (min-width: 768px) {
  .away-btn {
    padding: 7px;
  }
}
.away-btn .arrow {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  position: relative;
  background: #ce1518;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .away-btn .arrow {
    width: 33px;
    height: 33px;
  }
}
@media (min-width: 768px) {
  .away-btn .arrow {
    margin-right: 20px;
  }
}
.away-btn .arrow::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 5px;
  height: 5px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}
@media (min-width: 768px) {
  .away-btn .arrow::before {
    width: 10px;
    height: 10px;
    margin-left: -2px;
  }
}
.away-btn .text {
  display: inline-block;
  vertical-align: -1px;
  color: #ce1518;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 6px;
}
@media (min-width: 768px) {
  .away-btn .text {
    margin-right: 13px;
    font-size: 16px;
    vertical-align: -3px;
  }
}
.red-arrow-btn {
  background: #ce1518;
  border: 4px solid #ce1518;
  white-space: nowrap;
  padding: 4px;
}
@media (min-width: 768px) {
  .red-arrow-btn {
    padding: 7px;
  }
}
.red-arrow-btn .arrow {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  position: relative;
  background: #282c34;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .red-arrow-btn .arrow {
    width: 33px;
    height: 33px;
  }
}
@media (min-width: 768px) {
  .red-arrow-btn .arrow {
    margin-right: 20px;
  }
}
.red-arrow-btn .arrow::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 5px;
  height: 5px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}
@media (min-width: 768px) {
  .red-arrow-btn .arrow::before {
    width: 10px;
    height: 10px;
    margin-left: -2px;
  }
}
.red-arrow-btn .text {
  display: inline-block;
  vertical-align: -1px;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 6px;
}
@media (min-width: 768px) {
  .red-arrow-btn .text {
    margin-right: 13px;
    font-size: 16px;
    vertical-align: -3px;
  }
}
.white-arrow-btn {
  background: transparent;
  border: 2px solid white;
  white-space: nowrap;
  padding: 6px;
}
@media (min-width: 768px) {
  .white-arrow-btn {
    padding: 7px;
    border: 4px solid white;
  }
}
.white-arrow-btn .arrow {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  position: relative;
  background: white;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .white-arrow-btn .arrow {
    width: 33px;
    height: 33px;
  }
}
@media (min-width: 768px) {
  .white-arrow-btn .arrow {
    margin-right: 20px;
  }
}
.white-arrow-btn .arrow::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 5px;
  height: 5px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
@media (min-width: 768px) {
  .white-arrow-btn .arrow::before {
    width: 10px;
    height: 10px;
    margin-left: -2px;
  }
}
.white-arrow-btn .text {
  display: inline-block;
  vertical-align: -1px;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 6px;
}
@media (min-width: 768px) {
  .white-arrow-btn .text {
    margin-right: 13px;
    font-size: 16px;
    vertical-align: -3px;
  }
}
.white-more-btn {
  background: transparent;
  border: 1px solid white;
  padding: 9px 17px 7px;
}
@media (min-width: 768px) {
  .white-more-btn {
    padding: 16px 34px;
    border: 2px solid white;
  }
}
.white-more-btn .text {
  color: white;
  font-size: 9px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .white-more-btn .text {
    font-size: 16px;
  }
}
.red-btn {
  background: #ce1518;
  border: 4px solid #ce1518;
}
@media (min-width: 768px) {
  .red-btn {
    padding-left: 36px;
    padding-right: 36px;
  }
}
.red-btn .text {
  font-weight: 900;
  font-size: 16px;
}
.more-btn {
  background: transparent;
  border: 2px solid #ce1518;
}
.more-btn .text {
  text-transform: uppercase;
  color: #ce1518;
  font-size: 16px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .more-btn {
    padding: 20px 55px;
  }
}
.wide-btn {
  padding: 7px 20px;
}
@media (min-width: 768px) {
  .wide-btn {
    padding: 14px 60px;
  }
}
.service-more-btn {
  border-color: white;
  background: #d80927;
  border-width: 2px;
}
@media (min-width: 768px) {
  .service-more-btn {
    border-width: 4px;
  }
}
.service-more-btn .text {
  color: white;
  font-weight: bold;
}
.btn-with-number {
  white-space: nowrap;
  padding: 5px 10px;
}
@media (min-width: 768px) {
  .btn-with-number {
    padding: 10px 30px;
  }
}
.btn-with-number .text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  color: white;
}
.btn-with-number .btn-number {
  display: inline-block;
  vertical-align: middle;
  background: #171717;
  color: white;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  font-weight: 900;
  font-size: 12px;
  border-radius: 50%;
}
.more-btn-wrap {
  position: relative;
}
.more-btn-wrap::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  border-bottom: 1px solid #ce1518;
}
.more-btn-wrap .common-btn {
  position: relative;
}
.more-btn-wrap .common-btn::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  margin-right: 4px;
  width: 30px;
  background: white;
}
.more-btn-wrap .common-btn::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  margin-left: 4px;
  width: 30px;
  background: white;
}
input[type="text"],
textarea,
select,
input[type="password"] {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-family: Circe, Arial, sans-serif;
  font-size: 18px;
  line-height: 20px;
}
label {
  cursor: pointer;
}
button {
  outline: none;
  cursor: pointer;
}
input:focus {
  outline: none;
}
input::-webkit-input-placeholder {
  opacity: 0.8;
}
input::-moz-placeholder {
  opacity: 0.8;
}
select:not(.unstyled) {
  display: none;
}
.form-control {
  border-radius: 0;
  padding: 7px 9px;
  height: 36px;
  border: 1px solid #b8b8b8;
  background-color: #f5f5f5;
}
@media (min-width: 768px) {
  .form-control {
    padding: 15px 18px;
    height: 52px;
  }
}
.form-control:focus {
  border-color: #ce1518;
  box-shadow: none;
}
.form-control::placeholder {
  font-size: 14px;
  opacity: 1;
  color: #a7a7a7;
}
.form-control::-moz-placeholder {
  font-size: 14px;
  opacity: 1;
  color: #a7a7a7;
}
.form-control::-ms-input-placeholder {
  font-size: 14px;
  opacity: 1;
  color: #a7a7a7;
}
.form-control::-webkit-input-placeholder {
  font-size: 14px;
  opacity: 1;
  color: #a7a7a7;
}
@media (min-width: 768px) {
  .form-control::placeholder {
    text-transform: uppercase;
    font-size: 16px;
  }
  .form-control::-moz-placeholder {
    text-transform: uppercase;
    font-size: 16px;
  }
  .form-control::-ms-input-placeholder {
    text-transform: uppercase;
    font-size: 16px;
  }
  .form-control::-webkit-input-placeholder {
    text-transform: uppercase;
    font-size: 16px;
  }
}
textarea.form-control::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
textarea.form-control::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d8d8d8;
}
textarea.form-control::-webkit-scrollbar-thumb {
  background-color: #424242;
  cursor: all-scroll;
}
textarea.form-control::-webkit-scrollbar-thumb:hover {
  background-color: #ce1518;
}
.jq-selectbox__select {
  padding: 0 35px 0 13px;
  border-radius: 0;
  font: 16px/34px Circe, Arial, sans-serif;
  color: #a7a7a7;
  border: 1px solid #b8b8b8;
  background-color: #f5f5f5;
}
@media (min-width: 768px) {
  .jq-selectbox__select {
    font: 16px/50px Circe, Arial, sans-serif;
  }
}
.opened .jq-selectbox__select {
  border-color: #ce1518;
  box-shadow: none;
}
.jq-selectbox__select-text {
  display: block;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 16px;
}
.jq-selectbox__select-text.placeholder {
  color: #a7a7a7;
}
.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  font-size: 24px;
  color: #999;
}
.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 9px;
  display: inline-block;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Arial, Baskerville, monospace;
  color: #ce1518;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.25s, margin 0.25s;
  transform: scale(1, 0.8);
}
.jq-selectbox__trigger-arrow::before {
  content: "▼";
}
@media (min-width: 768px) {
  .jq-selectbox__trigger-arrow {
    margin-top: -25px;
  }
}
.opened .jq-selectbox__trigger-arrow {
  transform: scale(1, -0.8);
  margin-top: -20px;
}
@media (min-width: 768px) {
  .opened .jq-selectbox__trigger-arrow {
    margin-top: -27px;
  }
}
.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font: 14px/18px Circe, Arial, sans-serif;
  text-align: left;
  min-width: 100%;
}
.jq-selectbox__dropdown ul {
  max-height: 50vh;
  overflow: auto;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d8d8d8;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-thumb {
  background-color: #424242;
  cursor: all-scroll;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-thumb:hover {
  background-color: #ce1518;
}
.jq-selectbox__search {
  margin: 5px;
}
.jq-selectbox__search input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 27px 6px 8px;
  border: 1px solid #CCC;
  border-radius: 0;
  outline: none;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
  box-shadow: inset 1px 1px #F1F1F1;
  color: #333;
}
.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px;
}
.jq-selectbox ul {
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.jq-selectbox li {
  padding: 7px 10px 5px!important;
  color: #1a1a1a;
  margin-bottom: 0!important;
}
.jq-selectbox li::before {
  display: none!important;
}
.jq-selectbox li.selected {
  background-color: #d8d8d8;
  color: #1a1a1a;
}
.jq-selectbox li:hover {
  background-color: #ce1518;
  color: white;
}
.jq-selectbox li.disabled {
  color: #AAA;
}
.jq-selectbox li.disabled:hover {
  background: none;
}
.jq-selectbox li.optgroup {
  font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
  background: none;
  color: #1a1a1a;
  cursor: default;
}
.jq-selectbox li.option {
  padding-left: 25px;
}
.jq-file {
  display: inline-block;
  vertical-align: middle;
  padding: 7px 10px;
  border: 1px solid #b8b8b8;
  background-color: #b8b8b8;
  transition: box-shadow 0.25s;
  line-height: 1;
  position: relative;
}
@media (min-width: 768px) {
  .jq-file {
    padding: 14px 20px;
  }
}
.jq-file:hover {
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}
.jq-file:focus {
  text-decoration: none;
  box-shadow: 0 0 0 0 transparent;
}
.jq-file:active {
  box-shadow: 0 0 0 0 transparent;
  top: 1px;
}
.jq-file input {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.jq-file .jq-file__name {
  font-size: 16px;
  line-height: 1.4;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  color: white;
}
.jq-file .jq-file__browse {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 16px;
  color: #ce1518;
}
.jq-file.jq-file:hover .jq-file__browse {
  color: #ce1518;
}
.jq-file.jq-file:active .jq-file__browse {
  color: #ce1518;
}
.jq-file.jq-file.disabled,
.jq-file.jq-file.disabled .jq-file__name,
.jq-file.jq-file.disabled .jq-file__browse {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}
input[type=checkbox]:not(.unstyled),
input[type=radio]:not(.unstyled) {
  display: none;
}
.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  cursor: pointer;
  box-sizing: content-box;
}
.jq-checkbox.focused,
.jq-radio.focused {
  border: 1px solid #424242;
}
.jq-checkbox.disabled,
.jq-radio.disabled {
  opacity: 0.55;
}
.jq-checkbox {
  border-radius: 3px;
}
.jq-checkbox + span {
  max-width: calc(100% - 30px);
  vertical-align: top;
}
.jq-checkbox.checked {
  box-shadow: inset 0 0 0 2px white;
  background: #ce1518;
}
.jq-checkbox.checked .jq-checkbox__div {
  width: 9px;
  height: 5px;
  margin: 6px 0 0 6px;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  transform: rotate(-50deg);
  box-sizing: content-box;
}
.jq-radio {
  border-radius: 50%;
}
.jq-radio.checked {
  background: #ce1518;
  border-color: #ce1518;
}
.jq-radio.checked .jq-radio__div {
  width: 12px;
  height: 12px;
  margin: 6px 0 0 6px;
  border-radius: 50%;
  background: white;
}
.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  cursor: pointer;
  box-sizing: content-box;
}
.doc-type-selector {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.doc-type-selector .selector-item span {
  display: block;
  width: 235px;
  height: 50px;
  border: 1px solid #a7a7a7;
  background: #f5f5f5;
  position: relative;
  color: #a7a7a7;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 16px;
  font-weight: normal;
  padding-left: 50px;
}
.doc-type-selector .selector-item span::before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #a7a7a7;
  position: absolute;
  top: 15px;
  left: 15px;
}
.doc-type-selector .selector-item:not(:first-child) span {
  margin-left: -1px;
}
.doc-type-selector .selector-item input:checked + span {
  border-color: #ce1518;
  z-index: 1;
  color: #1a1a1a;
}
.doc-type-selector .selector-item input:checked + span::before {
  background: #ce1518;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.header {
  padding-top: 12px;
  padding-bottom: 12px;
}
.header .contact-col-wrap {
  width: 100%;
  text-align: right;
}
.header .contact-col-wrap > * {
  display: inline-block;
}
@media (min-width: 1280px) {
  .header .contact-col-wrap > * {
    display: block;
  }
}
@media (min-width: 1280px) {
  .header .contact-col-wrap {
    max-width: 210px!important;
  }
}
.header .contact-col {
  max-width: 180px;
}
.header .contact-col a {
  color: #1a1a1a;
  text-decoration: none;
}
.header .contact-col a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.header .contact-col a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.header .contact-col a:active,
.header .contact-col a:active:focus {
  color: #000000;
  text-decoration: none;
}
.header .contact-col > * {
  float: right;
}
.header .contact-col .phone {
  font-size: 24px;
  font-weight: bold;
}
.header .contact-col .email .footer-link {
  font-size: 15px;
}
.header .container {
  position: relative;
}
@media (min-width: 768px) {
  .header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (min-width: 480px) {
}
@media (min-width: 768px) {
}
.header .away-link-wrap {
  position: absolute;
  right: 0;
  padding-right: 0!important;
  top: 100%;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .header .away-link-wrap {
    margin-top: 25px;
    right: 0;
    padding-right: 15px!important;
  }
}
@media (min-width: 1920px) {
  .header .away-link-wrap {
    margin-top: 0;
  }
}
@media (max-width: 1919px) {
  .header .away-link-wrap .away-btn {
    border-color: #6a6a6a;
    background: #6a6a6a;
  }
  .header .away-link-wrap .away-btn .text {
    color: white;
  }
  .header .away-link-wrap .away-btn .arrow {
    background: white;
  }
  .header .away-link-wrap .away-btn .arrow::before {
    border-color: #ce1518;
  }
}
@media (min-width: 1920px) {
  .header .away-link-wrap {
    position: static;
  }
}
.header-sticky {
  top: 0;
  background-color: white;
  z-index: 500;
}
.main-menu {
  position: relative;
}
.main-menu .menu-btn {
  display: block;
}
@media (min-width: 1280px) {
  .main-menu .menu-btn {
    display: none;
  }
}
.main-menu a {
  color: #1a1a1a;
  text-decoration: none;
}
.main-menu a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.main-menu a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.main-menu a:active,
.main-menu a:active:focus {
  color: #000000;
  text-decoration: none;
}
.main-menu a {
  border-bottom: 1px solid transparent;
  display: inline-block;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .main-menu a {
    display: block;
  }
}
@media (min-width: 992px) {
  .main-menu a {
    font-size: 16px;
  }
}
.main-menu li {
  padding: 16px 0;
  display: block;
  list-style: none;
  margin-left: 14px;
  margin-right: 14px;
  position: relative;
}
@media (min-width: 768px) {
  .main-menu li {
    display: inline-block;
  }
}
@media (min-width: 1280px) {
  .main-menu li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 1920px) {
  .main-menu li {
    margin-left: 12px;
    margin-right: 12px;
  }
}
.main-menu li.active:after,
.main-menu li:hover:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  margin-top: -10px;
  left: 0;
  right: 0;
  border-bottom: 10px solid #ce1518;
}
@media (min-width: 1280px) {
  .main-menu li.active:after,
  .main-menu li:hover:after {
    margin-top: 16px;
  }
}
.main-menu .menu-wrap {
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  z-index: 10;
  width: 100vw;
  top: 100%;
  right: 0;
  background-color: #979899;
  box-shadow: none;
  transition: transform 0.25s, box-shadow 0.25s;
  margin-top: 12px;
  margin-right: -15px;
  display: none;
}
.main-menu .menu-wrap ul {
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .main-menu .menu-wrap {
    margin-top: 27px;
    margin-right: calc(360px - 50vw);
  }
}
@media (min-width: 992px) {
  .main-menu .menu-wrap {
    margin-right: calc(470px - 50vw);
  }
}
@media (min-width: 1280px) {
  .main-menu .menu-wrap {
    position: static;
    display: block;
    width: initial;
    background: none;
    box-shadow: none;
    padding: 0;
    transition: none;
    margin-right: 0;
    margin-top: 0;
  }
}
.main-menu.open .menu-wrap {
  display: block;
}
.away-list a {
  filter: grayscale(100%);
  opacity: 0.5;
  display: block;
  transition: filter 0.25s, opacity 0.25s;
}
.away-list a:hover,
.away-list a.hover {
  filter: grayscale(0);
  opacity: 1;
}
.away-list-wrap {
  display: none;
}
@media (min-width: 1280px) {
  .away-list-wrap {
    display: block;
  }
}
.desktop .header .phone {
  pointer-events: none;
}
.breadcrumbs {
  color: inherit;
  font-size: 10px;
}
@media (min-width: 768px) {
  .breadcrumbs {
    font-size: 14px;
  }
}
.breadcrumbs a {
  color: #1a1a1a;
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.breadcrumbs a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.breadcrumbs a:active,
.breadcrumbs a:active:focus {
  color: #000000;
  text-decoration: none;
}
.breadcrumbs a .text {
  border-bottom: 1px solid rgba(26, 26, 26, 0.5);
}
.breadcrumbs a:hover .text {
  border-color: transparent;
}
.breadcrumbs li {
  margin-bottom: 5px;
}
.breadcrumbs li:not(:last-of-type) {
  position: relative;
}
.breadcrumbs li:not(:last-of-type)::after {
  display: inline;
  content: '-';
  position: absolute;
  right: -4px;
  top: -2px;
  font-size: 1.2em;
  font-weight: 300;
}
@media (min-width: 768px) {
  .breadcrumbs li:not(:last-of-type)::after {
    right: -5px;
  }
}
.dark-back {
  color: white;
}
.dark-back .breadcrumbs {
  font-weight: 300;
}
.dark-back .breadcrumbs a {
  color: white;
  text-decoration: none;
}
.dark-back .breadcrumbs a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.dark-back .breadcrumbs a:focus {
  color: white;
  text-decoration: none;
}
.dark-back .breadcrumbs a:active,
.dark-back .breadcrumbs a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.dark-back .breadcrumbs a .text {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.slider .slide {
  display: block !important;
}
.slider .slide .img-wrap {
  display: inline-block;
}
.slider .slide ~ .slide {
  display: none !important;
}
.slick-slide:focus,
.slick-slide a {
  outline: none !important;
}
.slick-track {
  margin-left: 0;
}
.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  background: transparent;
  transition: background 0.25s;
  border: none;
  padding: 11px;
}
@media (min-width: 768px) {
  .slick-arrow {
    padding: 21px;
  }
}
.slick-arrow span {
  display: block;
  width: 22px;
  height: 22px;
  border-top: 1px solid white;
  transition: border 0.25s;
}
.slick-arrow.slick-prev {
  right: 100%;
  margin-right: -50px;
}
.slick-arrow.slick-prev span {
  border-left: 1px solid white;
  transform: translateX(5px) rotate(-45deg);
}
@media screen and (min-width: 768px) {
  .slick-arrow.slick-prev {
    margin-right: 0px;
  }
}
@media screen and (min-width: 1920px) {
  .slick-arrow.slick-prev {
    margin-right: -60px;
  }
}
.slick-arrow.slick-next {
  left: 100%;
  margin-left: -50px;
}
.slick-arrow.slick-next span {
  border-right: 1px solid white;
  transform: translateX(-5px) rotate(45deg);
}
@media screen and (min-width: 768px) {
  .slick-arrow.slick-next {
    margin-left: 0px;
  }
}
@media screen and (min-width: 1920px) {
  .slick-arrow.slick-next {
    margin-left: -60px;
  }
}
.slick-arrow:focus {
  outline: none !important;
}
.slick-dots button {
  display: block;
  font-size: 0;
  border: none;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li:not(:last-of-type) {
  margin-right: 15px;
}
.news-page-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.news-page-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
}
.news-block-slider {
  position: relative;
}
.news-block-slider .slide {
  padding-bottom: 20px;
}
@media (min-width: 1200px) {
  .news-block-slider .slide {
    padding-bottom: 0;
  }
}
.news-block-slider .slick-dots {
  position: absolute;
  bottom: 10px;
  left: 15px;
  z-index: 10;
}
@media (min-width: 1200px) {
  .news-block-slider .slick-dots {
    left: 30px;
  }
}
.action-slider {
  position: relative;
}
.action-slider .slick-dots {
  position: absolute;
  bottom: 40px;
  right: 15px;
  z-index: 10;
}
@media (min-width: 480px) {
  .action-slider .slick-dots {
    bottom: 10px;
  }
}
.action-slider .slick-dots button {
  background: white;
}
.action-slider .slick-dots .slick-active button {
  background: #ce1518;
}
.article-arrow {
  background: #72747a;
  width: 34px;
  height: 34px;
  padding: 11px;
}
@media (min-width: 768px) {
  .article-arrow {
    padding: 11px;
  }
}
.article-arrow span {
  width: 12px;
  height: 12px;
}
.article-arrow.slick-prev {
  margin-right: -30px;
}
@media screen and (min-width: 768px) {
  .article-arrow.slick-prev {
    margin-right: -30px;
  }
}
@media screen and (min-width: 1920px) {
  .article-arrow.slick-prev {
    margin-right: -30px;
  }
}
.article-arrow.slick-prev span {
  transform: translateX(3px) rotate(-45deg);
}
.article-arrow.slick-next {
  margin-left: -30px;
}
@media screen and (min-width: 768px) {
  .article-arrow.slick-next {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1920px) {
  .article-arrow.slick-next {
    margin-left: -30px;
  }
}
.article-arrow.slick-next span {
  transform: translateX(-3px) rotate(45deg);
}
.article-slider {
  padding-left: 40px;
  padding-right: 40px;
}
.main-slider .slide {
  display: flex!important;
  align-items: stretch;
}
@media (min-width: 768px) {
}
.main-slider .left-side {
  padding-top: 60px;
  width: 100%;
}
@media (min-width: 768px) {
  .main-slider .left-side {
    width: 50%;
  }
}
@media (min-width: 1920px) {
  .main-slider .left-side {
    width: 960px;
    padding-left: 65px;
  }
}
.main-slider .left-side .line-btn-wrap {
  margin-bottom: 30px;
}
.main-slider .right-side {
  position: relative;
  padding-top: 170px;
  width: 0;
}
@media (min-width: 768px) {
  .main-slider .right-side {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .main-slider .right-side {
    padding-top: 50px;
  }
}
@media (min-width: 1920px) {
  .main-slider .right-side {
    width: calc(100% - 960px);
  }
}
.main-slider .right-side .line-btn-wrap {
  position: absolute;
  right: 100%;
  width: 360px;
  bottom: 100px;
  animation-delay: 0.25s;
  animation-duration: 0.5s;
}
@media (min-width: 992px) {
  .main-slider .right-side .line-btn-wrap {
    left: 140px;
    right: 0;
    width: initial;
    bottom: 115px;
  }
}
@media (min-width: 1280px) {
  .main-slider .right-side .line-btn-wrap {
    left: 260px;
  }
}
@media (min-width: 1920px) {
  .main-slider .right-side .line-btn-wrap {
    left: 0;
  }
}
.main-slider .right-side .line-btn-wrap::before {
  display: block;
  content: "";
  border-bottom: 1px solid #ce1518;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.main-slider .right-side .img-wrap {
  animation-delay: 0.75s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .main-slider .right-side .img-wrap {
    padding-left: 60px;
  }
}
@media (min-width: 1280px) {
  .main-slider .right-side .img-wrap {
    padding-left: 140px;
  }
}
@media (min-width: 1920px) {
  .main-slider .right-side .img-wrap {
    padding-left: 60px;
  }
}
@media (min-width: 922px) {
  .main-slider .right-side .img-wrap img {
    margin-bottom: -65px;
  }
}
@media (min-width: 1280px) {
  .main-slider .right-side .img-wrap img {
    margin-bottom: -65px;
  }
}
@media (min-width: 1920px) {
  .main-slider .right-side .img-wrap img {
    margin-bottom: -75px;
  }
}
.main-slider .main-text {
  position: relative;
  z-index: 10;
  animation-duration: 0.5s;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .main-slider .main-text {
    margin-right: -160px;
    margin-bottom: 40px;
  }
}
@media (min-width: 1280px) {
  .main-slider .main-text {
    margin-right: -280px;
  }
}
@media (min-width: 1920px) {
  .main-slider .main-text {
    margin-right: 0;
  }
}
.main-slider .description {
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  color: white;
  font-weight: 300;
  line-height: 1.33333333;
  max-width: 630px;
  font-size: 10px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .main-slider .description {
    font-size: 18px;
    margin-bottom: 45px;
  }
}
@media (min-width: 1280px) {
  .main-slider .description {
    margin-bottom: 45px;
    font-size: 24px;
  }
}
@media (min-width: 1920px) {
  .main-slider .description {
    margin-bottom: 75px;
  }
}
.main-slider .white-more-btn {
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  margin-bottom: 30px;
}
.main-slider-dots {
  position: absolute;
  left: 0;
  width: calc(100vw - 30px);
  display: flex;
  bottom: 5px;
}
@media (min-width: 768px) {
  .main-slider-dots {
    width: 360px;
    bottom: 30px;
  }
}
@media (min-width: 992px) {
  .main-slider-dots {
    width: 470px;
  }
}
@media (min-width: 1280px) {
  .main-slider-dots {
    width: 630px;
  }
}
@media (min-width: 1920px) {
  .main-slider-dots {
    margin-left: 65px;
    bottom: 40px;
  }
}
.main-slider-dots li {
  width: 70px;
  padding-top: 3px;
  transition: all 1s;
}
.main-slider-dots li:not(:last-of-type) {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .main-slider-dots li:not(:last-of-type) {
    margin-right: 20px;
  }
}
.main-slider-dots li button {
  display: block;
  width: 100%;
  height: 2px;
  opacity: 0.25;
  background: white;
  transition: all 1s;
}
.main-slider-dots .slick-active {
  padding-top: 0;
}
.main-slider-dots .slick-active button {
  opacity: 1;
  height: 5px;
}
.range-slider {
  width: 100%;
  background: #dcdcdc;
  height: 5px;
  position: relative;
}
.range-slider .ui-slider-range {
  background: #ce1518;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.range-slider .ui-slider-handle {
  position: absolute;
  top: 0;
  cursor: grab;
  height: 100%;
}
.range-slider .ui-slider-handle:focus {
  outline: none;
}
.range-slider .ui-slider-handle.ui-state-active {
  cursor: grabbing;
}
.range-slider .ui-slider-handle::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6.5px 11.3px 6.5px;
  border-color: transparent transparent #ce1518 transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4px;
}
.range-slider .ui-slider-handle .handle-label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}
.range-slider-wrap {
  position: relative;
  padding-left: 135px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.range-slider-wrap .label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  max-width: 100px;
  line-height: 1.2;
}
ymaps[class*="-map-bg"] {
  background: white;
}
.map-block ymaps[class*="-ground-pane"] {
  filter: grayscale(1);
  opacity: 0.5;
}
ymaps[class*="-balloon"] {
  box-shadow: inset 0 0 0 1px rgba(206, 21, 24, 0.15), 0 5px 15px -7px rgba(0, 0, 0, 0.5);
}
ymaps[class*="-balloon__layout"],
ymaps[class*="-balloon__content"],
ymaps[class*="-balloon_layout_panel"] {
  background: #ce1518;
  box-shadow: none;
}
ymaps[class*="-balloon__content"] {
  padding: 20px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  font-family: Circe, Arial, sans-serif;
  line-height: 1.4;
}
ymaps[class*="-balloon__content"] span.metro {
  display: inline-block;
  vertical-align: 0;
  margin-right: 5px;
  width: 24px;
  height: 24px;
  padding-right: 1px;
  background: #424242;
  border-radius: 50%;
  line-height: 26px;
  text-align: center;
  margin-top: 5px;
}
ymaps[class*="-balloon-content__header"] {
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: none;
  font-size: 18px;
  margin-bottom: 10px;
}
ymaps[class*="-balloon-content__header"] span {
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
ymaps[class*="-balloon-content__header"] a {
  color: white;
  text-decoration: none;
}
ymaps[class*="-balloon-content__header"] a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
ymaps[class*="-balloon-content__header"] a:focus {
  color: white;
  text-decoration: none;
}
ymaps[class*="-balloon-content__header"] a:active,
ymaps[class*="-balloon-content__header"] a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
ymaps[class*="-balloon-content__header"] a:hover span {
  border-color: transparent;
}
ymaps[class*="-balloon-content__footer"] {
  color: white;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}
ymaps[class*="-balloon__close"],
ymaps[class*="balloon__close-button"] {
  box-shadow: none;
}
ymaps[class*="balloon__close-button"] {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQgLjdsLS43LS43TDcgNi4zLjcgMCAwIC43IDYuMyA3IDAgMTMuM2wuNy43TDcgNy43bDYuMyA2LjMuNy0uN0w3LjcgN3oiIGNsaXAtcnVsZT0iZXZlbm9kZCIgc3R5bGU9ImZpbGw6d2hpdGUiLz48L3N2Zz4=) 50% no-repeat;
  opacity: 0.5;
}
ymaps[class*="-balloon__tail"] {
  box-shadow: none;
}
ymaps[class*="-balloon__tail"]::after {
  background: #b7080b;
}
@media (max-width: 767px) {
  #map {
    max-height: 75vh;
  }
  .map-block #map {
    max-height: initial;
    height: 490px;
  }
}
@media (min-width: 768px) {
  .map-container {
    position: relative;
  }
}
.map-container .main-text-wrap {
  position: absolute;
  z-index: 10;
  top: 25px;
  pointer-events: none;
  text-shadow: 0 0 1px white, 0 0 5px white;
  max-width: 300px;
}
@media (min-width: 768px) {
  .map-container .main-text-wrap {
    top: 90px;
  }
}
@media (min-width: 992px) {
  .map-container .main-text-wrap {
    max-width: 350px;
  }
}
@media (min-width: 1920px) {
  .map-container .main-text-wrap {
    padding-left: 65px;
    max-width: 415px;
  }
}
.map-container .address-wrap {
  font-size: 12px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}
.map-container .address-wrap > * {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .map-container .address-wrap {
    flex-direction: column;
    font-size: 24px;
    margin-left: 0;
    margin-right: 0;
  }
  .map-container .address-wrap > * {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .map-container .address-wrap .address {
    margin-bottom: 30px;
  }
}
.map-container .address-wrap .address .ttu {
  font-weight: bold;
  font-size: 1.25em;
}
.map-container .address-wrap .phone {
  font-weight: bold;
}
@media (min-width: 768px) {
  .map-container .address-wrap .email {
    margin-bottom: 30px;
  }
}
.map-container .address-wrap .email a span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.25s;
}
.map-container .address-wrap .email a:hover span {
  border-color: transparent;
}
.map-container .form-wrap {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(16, 16, 16, 0.8);
  color: white;
  padding: 30px;
}
@media (min-width: 768px) {
  .map-container .form-wrap {
    top: 90px;
    bottom: auto;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .map-container .form-wrap {
    padding: 50px 70px;
  }
}
@media (min-width: 1920px) {
  .map-container .form-wrap {
    padding: 50px 130px;
  }
}
.map-container .form-wrap a {
  color: white;
  text-decoration: none;
}
.map-container .form-wrap a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.map-container .form-wrap a:focus {
  color: white;
  text-decoration: none;
}
.map-container .form-wrap a:active,
.map-container .form-wrap a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.map-container .form-wrap hr {
  border-color: rgba(255, 255, 255, 0.5);
}
.map-container .form-wrap .form-title {
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .map-container .form-wrap .form-title {
    width: 320px;
    font-size: 30px;
  }
}
.map-block {
  overflow: hidden;
  position: relative;
}
.footer {
  background: #3d4045;
  padding-top: 17px;
  padding-bottom: 17px;
}
.footer .social-icon {
  display: block;
  width: 38px;
  height: 38px;
  background-color: #1c1d1e;
  transition: background 0.25s;
}
.footer .social-icon::before {
  content: "";
  line-height: 38px;
  vertical-align: middle;
}
.footer .social-icon img {
  vertical-align: middle;
}
.footer .social-icon:hover {
  background-color: #ce1518;
}
@media (min-width: 768px) {
  .footer {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
.footer .logo {
  width: 32px;
}
@media (min-width: 768px) {
  .footer .logo {
    width: 53px;
  }
}
.footer .logo-row {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .footer .logo-row {
    margin-bottom: 30px;
  }
}
.footer .main-flex-item {
  width: 100%;
}
@media (min-width: 768px) {
  .footer .main-flex-item {
    width: initial;
  }
}
@media (min-width: 768px) {
  .footer .main-flex-item.contact-row {
    width: 260px;
  }
}
@media (min-width: 1280px) {
  .footer .main-flex-item.contact-row {
    width: 500px;
  }
  .footer .main-flex-item.contact-row .flex-wrap {
    flex-wrap: nowrap;
  }
}
.footer .search {
  border-bottom: 1px solid white;
}
.footer .search > * {
  display: inline-block;
  vertical-align: middle;
}
.footer .search input {
  font-size: 16px;
  border: 0;
  width: 85px;
  transition: width 0.25s;
  padding: 5px 10px 5px 0;
  color: white;
  background: transparent;
}
.footer .search input::-webkit-input-placeholder {
  color: white;
  opacity: 1;
}
.footer .search input::-moz-placeholder {
  color: white;
  opacity: 1;
}
.footer .search input:focus {
  width: 135px;
}
.footer .search input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.footer .search input:focus::-moz-placeholder {
  opacity: 0;
}
.footer .search .icon {
  font-size: 30px;
  color: white;
}
.footer a {
  color: white;
  text-decoration: none;
}
.footer a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.footer a:focus {
  color: white;
  text-decoration: none;
}
.footer a:active,
.footer a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.footer .footer-link {
  font-size: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.footer .footer-link:hover {
  border-color: transparent;
}
.footer .search-col {
  max-width: 180px;
}
.footer .search-col > * {
  float: right;
}
.footer .contact-col {
  max-width: 180px;
}
.footer .contact-col > * {
  float: right;
}
.footer .contact-col .phone {
  font-size: 24px;
  font-weight: bold;
}
.footer .contact-col .email .footer-link {
  font-size: 15px;
}
.footer .logo {
  display: inline-block;
  margin-right: 20px;
}
.footer .copyright {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
}
.footer .copyright .c {
  font-size: 0.9em;
  display: inline-block;
  vertical-align: 0.25em;
  margin-right: 0.5em;
}
.footer .social-list ~ .footer-link {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .footer .social-list ~ .footer-link {
    margin-top: 30px;
  }
}
.footer .social-list {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .footer .social-list {
    margin-bottom: 0;
  }
}
.footer .social-list-wrap {
  text-align: center;
}
@media (min-width: 768px) {
  .footer .social-list-wrap {
    text-align: left;
  }
}
.sitemap {
  background: white;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .sitemap {
    padding-top: 33px;
    padding-bottom: 32px;
  }
}
.sitemap a {
  color: #1a1a1a;
  text-decoration: none;
}
.sitemap a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.sitemap a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.sitemap a:active,
.sitemap a:active:focus {
  color: #000000;
  text-decoration: none;
}
.sitemap a {
  font-size: 15px;
  border-bottom: 1px solid transparent;
}
@media (min-width: 768px) {
  .sitemap a {
    font-size: 18px;
  }
}
.sitemap a:hover {
  border-color: rgba(26, 26, 26, 0.5);
}
.sitemap li {
  padding-left: 7px;
  padding-right: 7px;
}
.sitemap li.active a {
  border-bottom: 2px solid #ce1518;
}
.sitemap ul.list-unstyled {
  max-width: 1140px;
  width: calc(100% + 14px);
  margin-left: -7px;
  margin-right: -7px;
}
.contact-col-wrap,
.recall-btn-warp {
  width: 100%;
  text-align: right;
}
.contact-col-wrap > *,
.recall-btn-warp > * {
  display: inline-block;
}
@media (min-width: 1280px) {
  .contact-col-wrap > *,
  .recall-btn-warp > * {
    display: block;
  }
}
@media (min-width: 1280px) {
  .contact-col-wrap {
    max-width: 210px!important;
  }
}
.desktop .footer .phone {
  pointer-events: none;
}
.date-selector-wrap .jq-selectbox:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .date-selector-wrap {
    margin-bottom: 10px;
  }
}
.date-selector-wrap .jq-selectbox__dropdown {
  top: 100% !important;
}
@media (min-width: 992px) {
  .date-selector-wrap {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .news-btns-wrap.text-right {
    text-align: left;
  }
}
.news-btns-wrap .jq-file:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 480px) {
  .news-btns-wrap .jq-file:not(:last-child) {
    margin-bottom: 10px;
  }
}
.news-btns-wrap .common-btn {
  vertical-align: -1px;
}
.news-btns-wrap .giant-btn:not(:last-of-type) {
  margin-right: 15px;
}
@media (max-width: 480px) {
  .news-btns-wrap .giant-btn:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
.news-btns-wrap.docs-btns-wrap .jq-file {
  border-color: #ce1518;
  background: #f5f5f5;
}
.news-btns-wrap.docs-btns-wrap .jq-file .jq-file__name {
  color: #ce1518;
}
@media (max-width: 767px) {
  .mfp-content {
    max-height: 100vh;
    max-width: 100vw;
    overflow: auto;
  }
}
.mfp-image-holder .mfp-content {
  max-width: 85%;
}
@media (max-width: 767px) {
  .mfp-image-holder .mfp-content {
    max-height: initial;
    max-width: 85%;
    overflow: initial;
  }
}
.mfp-gallery button.mfp-close {
  top: 20px;
  right: -20px;
}
.popup {
  position: relative;
  max-width: 992px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  max-height: 95vh;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .popup {
    padding: 30px;
  }
}
.popup fieldset {
  padding: 0;
  border: none;
}
.popup fieldset legend {
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
}
.popup.popup-medium {
  max-width: 810px;
}
.popup.popup-small {
  max-width: 550px;
}
.popup .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}
@media (min-width: 768px) {
  .popup .title {
    font-size: 24px;
  }
}
.popup.public-popup {
  max-width: 805px;
  border-top: 8px solid #ce1518;
  background: #e6e6e6;
  padding: 55px 120px 45px;
}
.popup.public-popup .form-control {
  background: white;
  box-shadow: none;
}
.popup.public-popup .form-control:not(:focus) {
  border-color: white;
}
.popup.public-popup .jq-selectbox__select {
  background: white;
}
.popup.public-popup .jq-selectbox:not(.opened) .jq-selectbox__select {
  border-color: white;
}
.popup.public-popup .decorated-title {
  margin-left: -75px;
}
.popup.public-popup label {
  display: block;
  font-weight: 300;
  font-size: 16px;
  position: relative;
  padding-left: 35px;
}
.popup.public-popup label .jq-checkbox {
  position: absolute;
  top: -3px;
  left: 0;
}
.popup.public-popup small {
  font-size: 16px;
  font-weight: normal;
  display: block;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .popup.public-popup fieldset {
    position: relative;
    text-align: right;
  }
  .popup.public-popup fieldset legend {
    display: block;
    width: initial;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.popup.public-popup .date-selector-wrap .jq-selectbox:not(:last-child) {
  margin-right: 5px;
}
@media (min-width: 768px) {
  .popup.public-popup .date-selector-wrap .jq-selectbox:not(:last-child) {
    margin-right: 15px;
  }
}
.popup.public-popup .jq-selectbox__select {
  padding: 0 30px 0 10px;
}
@media (min-width: 768px) {
  .popup.public-popup .jq-selectbox__select {
    padding: 0 55px 0 30px;
  }
}
@media (max-width: 767px) {
  .popup.public-popup {
    padding: 30px 15px 20px;
  }
  .popup.public-popup .mfp-close {
    background: transparent;
  }
  .popup.public-popup .decorated-title {
    margin-bottom: 10px;
  }
  .popup.public-popup .decorated-title::before {
    display: none;
  }
  .popup.public-popup small {
    font-size: 14px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .popup.public-popup .captcha-wrap,
  .popup.public-popup input[name='captcha'] {
    max-width: 275px;
  }
}
.success-popup p {
  font-size: 23px;
}
.popup-notification {
  background: #ce1518;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 35px;
}
@media (min-width: 768px) {
  .popup-notification {
    padding: 65px;
  }
}
.popup-notification .mfp-close {
  display: none;
}
.route-selector .jq-selectbox {
  width: 100%;
}
.task-block {
  height: auto;
  min-height: 100%;
}
@media (min-width: 768px) {
  .task-block {
    height: auto;
  }
}
.task-block .label {
  color: #ce1518;
  margin-bottom: 10px;
}
.task-block .value {
  font-size: 110%;
}
.date-block {
  display: none;
}
.task-comments ul {
  margin-left: 10px;
}
.task-comments ul:not(.list-unstyled):not(.list-inline) li {
  padding-left: 20px;
  margin-bottom: 10px;
}
.files-wrap {
  position: relative;
  padding-right: 250px;
  margin-top: 20px;
  min-height: 60px;
}
.files-wrap [type='checkbox'] {
  display: none;
}
.files-wrap [type='checkbox'] ~ * {
  display: none;
}
.files-wrap [type='checkbox']:checked ~ * {
  display: inline-block;
}
.files-wrap .files-list {
  margin: 0;
}
.files-wrap .files-list li {
  display: flex;
  max-width: 500px;
}
.files-wrap .files-list li .filename {
  font-size: 14px;
  color: #595959;
  text-decoration: underline;
  margin-bottom: 10px;
}
.files-wrap .files-list li label {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  margin-bottom: 10px;
}
.files-wrap .add-file-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: 2px solid #ce1518;
  white-space: nowrap;
}
.files-wrap .add-file-btn .text {
  color: #ce1518;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
}
.files-wrap .add-file-btn .icon {
  color: #ce1518;
  font-size: 150%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.client-file-list .jq-file {
  margin-bottom: 10px;
  background: none;
  padding: 0;
  border: none;
}
.client-file-list .jq-file:hover {
  box-shadow: none;
}
.client-file-list .jq-file:active {
  top: 0;
}
.client-file-list .jq-file > * {
  font-size: 14px;
  color: #595959;
  text-decoration: underline;
  text-transform: none;
}
.client-file-list .label {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  margin-bottom: 10px;
}
.aside .fast-links-wrap,
.aside .information-wrap {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 0;
}
.aside .fast-links-wrap {
  padding: 80px 0 30px;
  background-color: #e6e6e6;
}
@media (min-width: 992px) {
  .aside .fast-links-wrap {
    padding: 20px 0 10px;
  }
}
.aside .fast-links-wrap .title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  color: #ce1518;
  position: absolute;
  top: 30px;
  left: 30px;
  padding-right: 60px;
}
@media (max-width: 991px) {
  .aside .information-content-wrap {
    overflow-y: auto;
    height: 100%;
  }
  .aside .information-wrap {
    transition: transform 0.25s, box-shadow 0.25s;
    padding: 30px;
    background: white;
  }
  .aside .information-wrap .section-wrap {
    position: relative;
  }
  .aside .information-wrap .underlined-title {
    position: sticky;
    background: white;
    top: 0;
    z-index: 1;
  }
}
@media (max-width: 991px) {
  .aside.open .fast-links-wrap,
  .aside.open .information-wrap {
    transform: translateX(-100%);
    box-shadow: -15px 0 37px rgba(0, 0, 0, 0.25);
  }
}

html {
  background: #3d4045;
}
html::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d8d8d8;
}
html::-webkit-scrollbar-thumb {
  background-color: #424242;
  cursor: all-scroll;
}
html::-webkit-scrollbar-thumb:hover {
  background-color: #ce1518;
}
body {
  max-width: 1920px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1280px) {
  .container {
    width: 1270px;
  }
}
@media (min-width: 1920px) {
  .container {
    width: 1850px;
  }
}
.sticky {
  position: sticky;
}
.cover-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tile {
  position: relative;
}
.tile.hover .edit,
.tile:hover .edit {
  opacity: 1;
  pointer-events: auto;
}
.tile.hover .img-wrap .date,
.tile:hover .img-wrap .date {
  background-color: #ce1518;
}
.tile.hover .tile-data .icon,
.tile:hover .tile-data .icon {
  color: #ce1518;
}
@media (min-width: 1920px) {
  .common-page-body-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.aside-title {
  font-size: 18px;
}
@media (min-width: 768px) {
  .aside-title {
    font-size: 22px;
  }
}
.aside-title .text {
  display: inline-block;
  font-weight: bold;
  vertical-align: middle;
}
.aside-title::before {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 15px;
  border: 0.3em solid #ce1518;
  content: "";
  vertical-align: middle;
}
.aside,
.main-content {
  box-sizing: border-box;
}
.aside {
  position: fixed;
  left: 100%;
  top: 0;
  height: 100%;
  z-index: 750;
  width: 100vw;
}
@media (min-width: 768px) {
  .aside {
    width: 50vw;
  }
}
@media (min-width: 992px) {
  .aside {
    position: sticky;
    z-index: auto;
    left: auto;
    top: 84px;
    height: initial;
    padding-left: 15px;
    padding-right: 15px;
    width: 210px;
    background: none;
  }
}
.main-content {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .main-content {
    width: calc(100% - 210px);
  }
}
.aside-layout {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}
@media (min-width: 992px) {
  .aside-layout {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.main-text {
  color: inherit;
  font-weight: 300;
  line-height: 1.14285714;
  margin-top: 0;
  font-size: 22px;
}
.main-text strong {
  font-weight: 800;
}
.main-text::after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  border: 0.15em solid #ce1518;
  vertical-align: baseline;
  margin-left: 15px;
}
@media (min-width: 992px) {
  .main-text::after {
    width: 45px;
    height: 45px;
    margin-left: 30px;
    border: 9px solid #ce1518;
  }
}
@media (min-width: 768px) {
  .main-text {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .main-text {
    font-size: 48px;
  }
}
@media (min-width: 1280px) {
  .main-text {
    font-size: 70px;
  }
}
.secondary-text {
  color: inherit;
  font-weight: 300;
  line-height: 1.14285714;
  margin-top: 0;
  font-size: 20px;
}
.secondary-text strong {
  font-weight: 800;
}
.secondary-text::after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  border: 0.15em solid #ce1518;
  vertical-align: baseline;
  margin-left: 15px;
}
@media (min-width: 992px) {
  .secondary-text::after {
    width: 45px;
    height: 45px;
    margin-left: 30px;
    border: 9px solid #ce1518;
  }
}
@media (min-width: 768px) {
  .secondary-text {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  .secondary-text {
    font-size: 42px;
  }
}
@media (min-width: 1280px) {
  .secondary-text {
    font-size: 50px;
  }
}
.main-slider-block {
  background-image: url(../img/bg/main_slider_bg.jpg);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  color: white;
}
.intersection-block {
  background: #21242a;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  line-height: 1.2;
  font-weight: 300;
  font-size: 12px;
}
@media (min-width: 768px) {
  .intersection-block {
    font-size: 24px;
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .intersection-block {
    font-size: 36px;
  }
}
.intersection-block .left-text {
  padding-left: 35px;
  position: relative;
}
@media (min-width: 768px) {
  .intersection-block .left-text {
    padding-left: 99px;
  }
}
@media (min-width: 1280px) {
  .intersection-block .left-text {
    padding-left: 160px;
  }
}
@media (min-width: 1920px) {
  .intersection-block .left-text {
    margin-left: 65px;
  }
}
.intersection-block .left-text::before {
  display: block;
  content: "";
  background: #ce1518;
  position: absolute;
  left: -15px;
  top: 50%;
  width: 35px;
  height: 35px;
  margin-top: -17.5px;
}
@media (min-width: 768px) {
  .intersection-block .left-text::before {
    width: 70px;
    height: 70px;
    margin-top: -35px;
    left: 0;
  }
}
.intersection-block .left-text::after {
  display: block;
  content: "";
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  width: 10px;
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media (min-width: 768px) {
  .intersection-block .left-text::after {
    width: 20px;
    height: 20px;
    left: 30px;
  }
}
.intersection-block .right-text .big {
  font-size: 1.66666667em;
  line-height: 1;
  white-space: nowrap;
}
.intersection-block .right-text .big strong {
  font-weight: bold;
}
.intersection-block .right-text .small {
  font-size: 0.5em;
}
@media (min-width: 1280px) {
  .intersection-block .right-text .small {
    white-space: nowrap;
  }
}
.intersection-block .flex-row {
  max-width: 1100px;
}
.solutions-list {
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 1280px) {
  .solutions-list {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.solutions-list a {
  color: white;
  text-decoration: none;
}
.solutions-list a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.solutions-list a:focus {
  color: white;
  text-decoration: none;
}
.solutions-list a:active,
.solutions-list a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.solutions-list .img-wrap {
  display: none;
}
.solutions-list a {
  display: block;
  height: 100%;
  text-transform: uppercase;
  background: transparent;
  transition: background 0.25s, color 0.25s;
  margin-left: -15px;
  margin-right: -15px;
}
.solutions-list a::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.solutions-list a span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.solutions-list a span::before {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 75%;
  border-bottom: 2px solid transparent;
  transition: border 0.25s;
  margin-top: 5px;
}
@media (min-width: 1280px) {
  .solutions-list a span::before {
    margin-top: 10px;
  }
}
.solutions-list a:hover {
  background: white;
  color: #ce1518;
}
.solutions-list a:hover span::before {
  border-color: #ce1518;
}
.solutions-list .flex-item {
  transition: transform 0.25s, z-index 0.25s, box-shadow 0.25s;
  position: relative;
  z-index: 0;
  box-shadow: 0 0 0 rgba(14, 17, 21, 0.68);
}
.solutions-list .flex-item:hover {
  transform: scale(1.05);
  z-index: 25;
  box-shadow: 0 14px 57px rgba(14, 17, 21, 0.68);
}
@media (max-width: 767px) {
  .solutions-list .flex-item {
    width: 100%;
    background: rgba(25, 28, 33, 0.7);
    margin-bottom: 5px;
  }
  .solutions-list .flex-item:nth-of-type(2n) {
    background: rgba(25, 28, 33, 0.5);
  }
  .solutions-list a {
    padding: 12px 15px 10px;
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .solutions-list .flex-item {
    width: 33.33333333%;
    background: rgba(25, 28, 33, 0.7);
    margin-bottom: 35px;
  }
  .solutions-list .flex-item:nth-of-type(2n) {
    background: rgba(25, 28, 33, 0.5);
  }
  .solutions-list a {
    padding: 15px;
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .solutions-list .flex-item {
    width: 33.33333333%;
    background: rgba(25, 28, 33, 0.7);
    margin-bottom: 35px;
  }
  .solutions-list .flex-item:nth-of-type(2n) {
    background: rgba(25, 28, 33, 0.5);
  }
  .solutions-list a {
    padding: 15px 30px;
    font-size: 16px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .solutions-list .flex-item {
    width: 25%;
    background: rgba(25, 28, 33, 0.7);
    margin-bottom: 35px;
  }
  .solutions-list .flex-item:nth-of-type(1),
  .solutions-list .flex-item:nth-of-type(3n),
  .solutions-list .flex-item:nth-of-type(8n) {
    background: rgba(25, 28, 33, 0.5);
  }
  .solutions-list a {
    padding: 30px;
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .solutions-list {
    padding-top: 195px;
  }
  .solutions-list .img-wrap {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
  }
  .solutions-list a {
    padding: 30px 50px;
    font-size: 18px;
  }
  .solutions-list a:hover + .img-wrap {
    opacity: 1;
  }
  .solutions-list .flex-item {
    position: relative;
    width: 20%;
    background: rgba(25, 28, 33, 0.7);
    margin-bottom: 35px;
  }
  .solutions-list .flex-item:nth-of-type(2n - 1),
  .solutions-list .flex-item:nth-of-type(4n - 1),
  .solutions-list .flex-item:nth-of-type(6n - 1) {
    background: rgba(25, 28, 33, 0.5);
  }
}
.solutions-block {
  background-image: url(../img/bg/solutions_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}
@media (min-width: 768px) {
  .solutions-block {
    padding-top: 70px;
    padding-bottom: 20px;
  }
}
.solutions-block .main-text-wrap {
  max-width: 1250px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .solutions-block .main-text-wrap {
    margin-bottom: 50px;
  }
}
@media (min-width: 1920px) {
  .solutions-block .main-text-wrap {
    padding-left: 65px;
    max-width: 1310px;
  }
}
@media (max-width: 767px) {
  .solutions-block .main-text-wrap .main-text {
    font-size: 15px;
    margin-bottom: 5px;
  }
}
.solutions-block .description {
  font-size: 10px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .solutions-block .description {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .sphere-solutions-list {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.sphere-solutions-list a {
  color: #1a1a1a;
  text-decoration: none;
}
.sphere-solutions-list a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.sphere-solutions-list a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.sphere-solutions-list a:active,
.sphere-solutions-list a:active:focus {
  color: #000000;
  text-decoration: none;
}
.sphere-solutions-list a {
  display: block;
  height: 100%;
  text-align: center;
  background: transparent;
  transition: background 0.25s, color 0.25s;
  margin-left: -15px;
  margin-right: -15px;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .sphere-solutions-list a {
    text-transform: none;
  }
}
.sphere-solutions-list a .icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 42px;
  width: 2em;
  height: 1em;
  color: #838890;
  transition: color 0.25s;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .sphere-solutions-list a .icon {
    font-size: 57px;
  }
}
@media (min-width: 1280px) {
  .sphere-solutions-list a .icon {
    font-size: 75px;
  }
}
.sphere-solutions-list a:hover {
  background: #ce1518;
  color: white;
}
.sphere-solutions-list a:hover .icon {
  color: white;
}
.sphere-solutions-list .flex-item {
  transition: transform 0.25s, z-index 0.25s, box-shadow 0.25s;
  position: relative;
  z-index: 0;
  box-shadow: 0 0 0 rgba(79, 4, 7, 0.67);
}
.sphere-solutions-list .flex-item:hover {
  transform: scale(1.05);
  z-index: 25;
  box-shadow: 0 22px 24px rgba(79, 4, 7, 0.67);
}
@media (max-width: 767px) {
  .sphere-solutions-list .flex-item {
    position: relative;
    width: 33.33333333%;
    background: white;
  }
  .sphere-solutions-list .flex-item:nth-of-type(odd) {
    background: #e5e7ea;
  }
  .sphere-solutions-list a {
    padding: 12px 10px 10px;
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sphere-solutions-list .flex-item {
    position: relative;
    width: 33.33333333%;
    background: white;
  }
  .sphere-solutions-list .flex-item:nth-of-type(odd) {
    background: #e5e7ea;
  }
  .sphere-solutions-list a {
    padding: 40px 10px 15px;
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .sphere-solutions-list .flex-item {
    position: relative;
    width: 16.66666667%;
    background: white;
  }
  .sphere-solutions-list .flex-item:nth-of-type(odd) {
    background: #e5e7ea;
  }
  .sphere-solutions-list a {
    padding: 30px 10px 15px;
    font-size: 18px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .sphere-solutions-list .flex-item {
    position: relative;
    width: 33.33333333%;
    background: white;
  }
  .sphere-solutions-list .flex-item:nth-of-type(odd) {
    background: #e5e7ea;
  }
  .sphere-solutions-list a {
    padding: 40px 10px 15px;
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .sphere-solutions-list a {
    padding: 40px 40px 15px;
    font-size: 18px;
  }
  .sphere-solutions-list .flex-item {
    position: relative;
    width: 33.33333333%;
    background: white;
  }
  .sphere-solutions-list .flex-item:nth-of-type(odd) {
    background: #e5e7ea;
  }
}
.sphere-solutions-block {
  background-image: url(../img/bg/sphere_solutions_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}
@media (min-width: 768px) {
  .sphere-solutions-block {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .sphere-solutions-block {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1920px) {
  .sphere-solutions-block .left-block-wrap {
    padding-left: 65px;
    padding-right: 115px;
  }
}
.sphere-solutions-block .right-block-wrap {
  position: relative;
  box-shadow: 0 31px 62px #0e1115;
  margin-bottom: 35px;
  height: 390px;
}
@media (min-width: 1280px) {
  .sphere-solutions-block .right-block-wrap {
    height: 622px;
  }
}
@media (min-width: 1920px) {
  .sphere-solutions-block .right-block-wrap {
    height: 630px;
  }
}
.sphere-solutions-block .right-block-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sphere-solutions-block .right-block-wrap .text-block {
  position: absolute;
  left: 90px;
  bottom: 70px;
  width: 290px;
  min-height: 290px;
  background: #ce1518;
  padding: 35px 30px 80px;
  font-size: 14px ;
  font-weight: 300;
}
.sphere-solutions-block .right-block-wrap .text-block::after {
  display: block;
  content: "";
  width: 26px;
  height: 26px;
  border: 6px solid white;
  position: absolute;
  right: 20px;
  bottom: 25px;
}
.sphere-solutions-block .main-text {
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1279px) {
  .sphere-solutions-block .main-text {
    font-size: 46px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .sphere-solutions-block .main-text {
    font-size: 66px;
  }
}
.sphere-solutions-block .main-text::after {
  border-color: white;
}
.sphere-solutions-block .description {
  margin-bottom: 15px;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .sphere-solutions-block .description {
    font-size: 26px;
    margin-bottom: 43px;
  }
}
.sphere-solutions-block .description strong {
  font-weight: bold;
}
.directions-list .flex-item {
  height: 180px;
  width: 100%;
  margin-bottom: 15px;
}
.directions-list .flex-item a {
  color: white;
  text-decoration: none;
}
.directions-list .flex-item a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.directions-list .flex-item a:focus {
  color: white;
  text-decoration: none;
}
.directions-list .flex-item a:active,
.directions-list .flex-item a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
@media (min-width: 768px) {
  .directions-list .flex-item {
    margin-bottom: 30px;
  }
}
.directions-list .flex-item .text-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 45px;
  bottom: 45px;
  overflow: hidden;
  transition: all 0.25s;
}
.directions-list .flex-item .text-box .title {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 45%;
  padding: 0 20px;
  line-height: 1;
  background: rgba(27, 30, 34, 0.85);
  z-index: 1;
  transition: all 0.25s;
  font-size: 16px;
}
@media (min-width: 992px) {
  .directions-list .flex-item .text-box .title {
    width: 33%;
  }
}
@media (min-width: 1280px) {
  .directions-list .flex-item .text-box .title {
    font-size: 22px;
  }
}
.directions-list .flex-item .text-box .title::before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  height: 100%;
}
.directions-list .flex-item .text-box .title span.text {
  display: inline-block;
  vertical-align: middle;
}
.directions-list .flex-item .text-box .description {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px 0 15px;
  line-height: 1.1;
  background: rgba(27, 30, 34, 0.85);
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.25s;
  font-size: 12px;
  width: 55%;
  display: none;
}
@media (min-width: 768px) {
  .directions-list .flex-item .text-box .description {
    display: block;
  }
}
@media (min-width: 992px) {
  .directions-list .flex-item .text-box .description {
    width: 67%;
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .directions-list .flex-item .text-box .description {
    font-size: 16px;
  }
}
.directions-list .flex-item .text-box .description span.line {
  position: absolute;
  top: 30px;
  bottom: 30px;
  left: 0;
  border-right: 1px solid white;
}
@media (min-width: 992px) {
  .directions-list .flex-item .text-box .description span.line {
    top: 10px;
    bottom: 10px;
  }
}
.directions-list .flex-item .text-box .description::before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  height: 100%;
}
.directions-list .flex-item .text-box .description span.text {
  display: inline-block;
  vertical-align: middle;
}
.directions-list .flex-item .text-box .description::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.5px 0 6.5px 11.3px;
  border-color: transparent transparent transparent white;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.directions-list .flex-item a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.directions-list .flex-item a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.directions-list .flex-item a:hover .text-box,
.directions-list .flex-item a .hover .text-box {
  top: 45px;
  bottom: 45px;
}
@media (min-width: 768px) {
  .directions-list .flex-item a:hover .text-box,
  .directions-list .flex-item a .hover .text-box {
    top: 25px;
    bottom: 25px;
  }
}
@media (min-width: 992px) {
  .directions-list .flex-item a:hover .text-box,
  .directions-list .flex-item a .hover .text-box {
    top: 45px;
    bottom: 45px;
  }
}
.directions-list .flex-item a:hover .text-box .title,
.directions-list .flex-item a .hover .text-box .title {
  background: #ce1518;
}
.directions-list .flex-item a:hover .text-box .description,
.directions-list .flex-item a .hover .text-box .description {
  opacity: 1;
  background: #ce1518;
  transform: translateX(0);
}
@media (min-width: 768px) {
  .directions-list .flex-item {
    width: 50%;
  }
}
@media (min-width: 1920px) {
  .directions-list .flex-item {
    width: 33.3333333%;
  }
}
.directions-block {
  color: #1a1a1a;
  padding-top: 20px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .directions-block {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
.directions-block .main-text-wrap {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .directions-block .main-text-wrap {
    margin-bottom: 50px;
  }
}
@media (min-width: 1920px) {
  .directions-block .main-text-wrap {
    padding-left: 65px;
    max-width: 1300px;
  }
}
.calculation-block {
  background: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .calculation-block {
    background-image: url(../img/bg/calculation_bg.jpg);
    background-size: cover;
    background-position: 70% center;
    padding-bottom: 110px;
  }
}
@media (min-width: 992px) {
  .calculation-block {
    background-position: 55% center;
  }
}
@media (min-width: 1280px) {
  .calculation-block {
    background-position: center;
  }
}
@media (min-width: 1280px) {
  .calculation-block .main-text-wrap {
    max-width: 980px;
  }
}
@media (min-width: 1920px) {
  .calculation-block .main-text-wrap {
    padding-left: 65px;
    max-width: 1200px;
  }
}
.calculation-block .jq-selectbox {
  display: block;
}
.calculation-block .main-text {
  margin-bottom: 20px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .calculation-block .main-text {
    max-width: 60%;
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .calculation-block .main-text {
    max-width: 750px;
    font-size: 48px;
    margin-bottom: 70px;
  }
}
@media (min-width: 1280px) {
  .calculation-block .main-text {
    font-size: 48px;
    max-width: initial;
  }
}
@media (min-width: 1920px) {
  .calculation-block .main-text {
    font-size: 70px;
  }
}
@media (min-width: 768px) {
  .calculation-block .promo-text {
    max-width: 60%;
  }
}
@media (min-width: 992px) {
  .calculation-block .promo-text {
    padding-top: 125px;
    max-width: initial;
  }
}
.calculation-block .promo-text p {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.2em;
}
.calculation-block .promo-text li {
  font-size: 20px;
}
.about-top {
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 10px;
  background: url(../img/bg/header_bar_bg.jpg);
  background-position: center top;
  background-size: cover;
  font-weight: 300;
}
.about-top p strong {
  font-weight: bold;
}
@media (min-width: 768px) {
  .about-top {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }
}
.about-top .main-text-wrap {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .about-top .main-text-wrap {
    margin-bottom: 50px;
  }
}
@media (min-width: 1920px) {
  .about-top .main-text-wrap {
    padding-left: 65px;
    max-width: 1300px;
  }
}
.merit-tile .top-box {
  background: #f3f3f3;
  padding: 1.66666667em 1.66666667em 3.33333333em;
  font-size: 1em;
  margin-bottom: 1.66666667em;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .merit-tile .top-box {
    text-align: left;
  }
}
.merit-tile .top-box .icon {
  font-size: 6em;
}
@media (min-width: 768px) {
  .merit-tile .top-box .icon {
    font-size: 4.44444444em;
  }
}
.merit-tile .top-box::after {
  display: block;
  content: "";
  border-bottom: 6px solid #ce1518;
  position: absolute;
  bottom: 1.66666667em;
  left: 0;
  width: 66.6666667%;
}
.merit-tile .title {
  font-weight: 900;
}
.merits-slider .slide {
  padding-left: 7px;
  padding-right: 7px;
}
@media (min-width: 768px) {
  .merits-slider .slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.merits-slider .slick-arrow {
  background: #394249;
  padding: 11px;
  margin-top: -30px;
}
@media (min-width: 768px) {
  .merits-slider .slick-arrow {
    padding: 11px;
  }
}
.merits-slider .slick-arrow.slick-prev {
  margin-right: -30px;
}
@media screen and (min-width: 768px) {
  .merits-slider .slick-arrow.slick-prev {
    margin-right: -30px;
  }
}
.merits-slider .slick-arrow.slick-next {
  margin-left: -30px;
}
@media screen and (min-width: 768px) {
  .merits-slider .slick-arrow.slick-next {
    margin-left: -30px;
  }
}
.about-merits {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 10px;
}
@media (min-width: 768px) {
  .about-merits {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .about-merits {
    font-size: 10px;
  }
}
@media (min-width: 1280px) {
  .about-merits {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .about-merits {
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .about-merits .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.about-merits .main-text {
  padding-right: 10%;
}
@media (min-width: 768px) {
  .about-merits .main-text {
    padding-right: 0;
  }
}
.video-block {
  position: relative;
  background: black;
}
.video-block .video-sizer {
  padding-bottom: 56.25%;
}
.video-block .video-wrapper {
  max-width: 1690px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.video-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-block .preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1s;
}
.video-block .preview .main-text {
  max-width: 1100px;
  position: relative;
  z-index: 2;
  color: white;
}
@media (max-width: 767px) {
  .video-block .preview .main-text {
    font-size: 15px;
  }
}
.video-block .preview .main-text-wrap {
  padding-top: 10px;
}
@media (min-width: 768px) {
  .video-block .preview .main-text-wrap {
    padding-top: 40px;
  }
}
@media (min-width: 1280px) {
  .video-block .preview .main-text-wrap {
    padding-top: 85px;
  }
}
@media (min-width: 1920px) {
  .video-block .preview .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.video-block .preview .review-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-block .preview button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.video-block .preview button img {
  width: 74px;
}
@media (min-width: 768px) {
  .video-block .preview button img {
    width: 146.5px;
  }
}
@media (min-width: 1280px) {
  .video-block .preview button img {
    width: 293px;
  }
}
.video-block.show .preview {
  opacity: 0;
  pointer-events: none;
}
.about-history {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 10px;
}
@media (min-width: 768px) {
  .about-history {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .about-history .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.time-row {
  font-size: 10px;
}
@media (min-width: 768px) {
  .time-row {
    font-size: 10px;
  }
}
@media (min-width: 992px) {
  .time-row {
    font-size: 12px;
  }
}
@media (min-width: 1280px) {
  .time-row {
    font-size: 16px;
  }
}
.time-row .year {
  display: inline-block;
  font-size: 1.875em;
  font-weight: 300;
  position: relative;
  background: white;
  padding-left: 10px;
  padding-right: 10px;
}
.time-row .text {
  font-size: 1em;
  line-height: 1.2;
  max-width: 90%;
  text-align: left;
}
.time-row hr {
  border-width: 4px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.time-row > .flex-item {
  width: calc(100% / 3 );
  flex-grow: 1;
  flex-shrink: 1000;
}
@media (min-width: 768px) {
  .time-row > .flex-item {
    width: calc(100% / 8 * 6);
  }
}
.time-row > .flex-item hr {
  border-color: #6595b1;
}
.time-row > .flex-item:not(:last-child) .year {
  position: relative;
}
.time-row > .flex-item:not(:last-child) .year::after {
  white-space: nowrap;
  display: block;
  content: '• • • • • • • •';
  position: absolute;
  color: #c9c9c9;
  left: 100%;
  top: 0;
  width: 3.33333333em;
}
@media (min-width: 1920px) {
  .time-row > .flex-item:not(:last-child) .year::after {
    margin-left: 0.83333333em;
  }
}
.time-row > .flex-item:first-child,
.time-row > .flex-item:last-child {
  width: calc(100% / 3 );
  flex-grow: 1000;
  flex-shrink: 1;
}
@media (min-width: 768px) {
  .time-row > .flex-item:first-child,
  .time-row > .flex-item:last-child {
    width: calc(100% / 8 + 30px);
  }
}
.time-row > .flex-item:first-child hr {
  border-color: #c9c9c9;
}
.time-row > .flex-item:first-child .year {
  margin-left: -10px;
}
@media (min-width: 1920px) {
  .time-row > .flex-item:first-child .year::after {
    margin-left: 0.8em;
  }
}
.time-row > .flex-item:last-child {
  text-align: right;
}
.time-row > .flex-item:last-child .year {
  margin-right: -10px;
}
.time-row > .flex-item:last-child hr {
  border-color: #c10f28;
}
.time-row .year-row > .flex-item {
  width: 100%;
}
.time-row .year-row > .flex-item .year {
  margin-left: -7px;
  transform: translateX(-50%);
}
.time-row .year-row > .flex-item:last-child {
  width: 0;
}
.time-row .year-row > .flex-item:last-child .year {
  margin-left: 7px;
  transform: translateX(-50%);
}
.history-tile {
  font-size: 10px;
}
@media (min-width: 768px) {
  .history-tile {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .history-tile {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .history-tile {
    font-size: 22px;
  }
}
.history-tile .img-wrap {
  margin-bottom: 1.36363636em;
}
@media (min-width: 768px) {
  .history-tile {
    position: relative;
    padding-left: 11.36363636em;
    min-height: 9.54545455em;
  }
  .history-tile .img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 9.54545455em;
  }
  .history-tile .text-wrap {
    padding-left: 1.81818182em;
    position: relative;
  }
  .history-tile .text-wrap::before {
    display: block;
    content: "";
    height: 3.86363636em;
    border-left: 1px solid #ce1518;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.history-tile .text-wrap .year {
  font-size: 2.18181818em;
  font-weight: 900;
  margin-bottom: 0.20833333em;
  line-height: 1;
}
.history-tile .text-wrap .title {
  font-size: 1em;
  margin: 0 0 0.45454545em;
}
.history-tile .text-wrap .description {
  font-size: 1em;
}
.history-tile .text-wrap .description .contracts {
  font-size: 0.72727273em;
  color: #898989;
}
@media (min-width: 768px) {
  .history-slider {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.history-slider .slide {
  padding-left: 7px;
  padding-right: 7px;
}
@media (min-width: 768px) {
  .history-slider .slide {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .history-slider {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .history-slider .slide {
    width: 50%;
  }
}
@media (min-width: 1920px) {
  .history-slider .slide {
    width: 33.33333333%;
  }
}
.history-slider .slick-arrow {
  background: #394249;
  padding: 11px;
  margin-top: -55px;
}
@media (min-width: 768px) {
  .history-slider .slick-arrow {
    padding: 11px;
  }
}
.history-slider .slick-arrow.slick-prev {
  margin-right: -30px;
}
@media screen and (min-width: 768px) {
  .history-slider .slick-arrow.slick-prev {
    margin-right: -30px;
  }
}
.history-slider .slick-arrow.slick-next {
  margin-left: -30px;
}
@media screen and (min-width: 768px) {
  .history-slider .slick-arrow.slick-next {
    margin-left: -30px;
  }
}
.about-mission {
  background: #d8d9d3;
}
.about-mission .left-block-wrap {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .about-mission .left-block-wrap {
    padding-top: 30px;
  }
}
@media (min-width: 1280px) {
  .about-mission .left-block-wrap {
    padding-top: 100px;
  }
}
@media (min-width: 1920px) {
  .about-mission .left-block-wrap {
    padding-left: 65px;
    padding-right: 115px;
  }
}
.about-mission .main-text {
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1279px) {
  .about-mission .main-text {
    font-size: 46px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .about-mission .main-text {
    font-size: 66px;
  }
}
.about-mission .description {
  margin-bottom: 15px;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .about-mission .description {
    font-size: 26px;
    margin-bottom: 43px;
  }
}
.about-mission .description strong {
  font-weight: bold;
}
.about-mission .more-text {
  font-size: 10px;
}
@media (min-width: 768px) {
  .about-mission .more-text {
    font-size: 14px;
  }
}
.about-synenergy {
  padding-top: 20px;
  background-color: #21242a;
  background-image: url(../img/bg/synenergy_bg_small.jpg);
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 70vw;
  font-size: 10px;
  font-weight: 300;
}
@media (min-width: 992px) {
  .about-synenergy {
    background-image: url(../img/bg/synenergy_bg_big.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .about-synenergy {
    padding-top: 50px;
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .about-synenergy {
    min-height: 740px;
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .about-synenergy {
    min-height: 1000px;
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .about-synenergy {
    min-height: 1064px;
  }
}
.about-synenergy .more-text {
  font-size: 10px;
}
@media (min-width: 768px) {
  .about-synenergy .more-text {
    font-size: 14px;
  }
}
.about-synenergy .main-text {
  padding-right: 10%;
}
@media (min-width: 768px) {
  .about-synenergy .main-text {
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .about-synenergy .main-text-wrap {
    max-width: 600px;
  }
  .about-synenergy .main-text-wrap .more-text {
    max-width: 380px;
  }
}
@media (min-width: 1280px) {
  .about-synenergy .main-text-wrap {
    max-width: 730px;
  }
  .about-synenergy .main-text-wrap .more-text {
    max-width: 500px;
  }
}
@media (min-width: 1920px) {
  .about-synenergy .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
  .about-synenergy .main-text-wrap .more-text {
    max-width: 100%;
  }
}
.about-synenergy .abbr-list {
  border-spacing: 0 0.88888889em;
  border-collapse: separate;
}
.about-synenergy .abbr-list tr {
  padding-top: 0.27777778em;
  padding-bottom: 0.27777778em;
}
.about-synenergy .abbr-list td:first-child {
  padding-right: 1.27777778em;
  text-align: right;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.about-synenergy .abbr-list td:last-child {
  padding-left: 1.27777778em;
}
.techno-slider .slick-arrow.slick-prev {
  margin-right: -50px;
}
@media screen and (min-width: 768px) {
  .techno-slider .slick-arrow.slick-prev {
    margin-right: -64px;
  }
}
@media screen and (min-width: 1920px) {
  .techno-slider .slick-arrow.slick-prev {
    margin-right: -60px;
  }
}
.techno-slider .slick-arrow.slick-next {
  margin-left: -50px;
}
@media screen and (min-width: 768px) {
  .techno-slider .slick-arrow.slick-next {
    margin-left: -64px;
  }
}
@media screen and (min-width: 1920px) {
  .techno-slider .slick-arrow.slick-next {
    margin-left: -60px;
  }
}
.techno-slider .slide {
  position: relative;
}
.techno-slider .slide .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .techno-slider .slide .container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .techno-slider .slide .text-wrap {
    padding-right: 300px;
    padding-left: 20px;
  }
}
@media (min-width: 1280px) {
  .techno-slider .slide .text-wrap {
    padding-right: 200px;
    padding-left: 0;
  }
}
@media (min-width: 1920px) {
  .techno-slider .slide .text-wrap {
    padding-right: 720px;
    padding-left: 65px;
  }
}
.techno-slider .slide .main-text {
  font-size: 16px;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .techno-slider .slide .main-text {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .techno-slider .slide .main-text {
    font-size: 26px;
  }
}
@media (min-width: 1280px) {
  .techno-slider .slide .main-text {
    font-size: 55px;
  }
}
@media (min-width: 992px) {
  .techno-slider .slide .main-text::after {
    width: 0.8em;
    height: 0.8em;
    border: 0.15em solid #ce1518;
  }
}
@media (min-width: 1280px) {
  .techno-slider .slide .main-text::after {
    width: 45px;
    height: 45px;
    margin-left: 30px;
    border: 9px solid #ce1518;
  }
}
.techno-slider .slide .description {
  max-width: 540px;
  font-size: 11px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .techno-slider .slide .description {
    font-size: 18px;
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .techno-slider .slide .description {
    font-size: 14px;
    max-width: 400px;
  }
}
@media (min-width: 1280px) {
  .techno-slider .slide .description {
    font-size: 18px;
    max-width: 540px;
  }
}
.techno-slider-dots {
  position: absolute;
  right: 0;
  width: calc(100vw - 30px);
  display: flex;
  bottom: 5px;
}
@media (min-width: 768px) {
  .techno-slider-dots {
    width: 360px;
    bottom: 30px;
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .techno-slider-dots {
    width: 470px;
    margin-right: 65px;
  }
}
@media (min-width: 1280px) {
  .techno-slider-dots {
    width: 630px;
  }
}
@media (min-width: 1920px) {
  .techno-slider-dots {
    bottom: 40px;
  }
}
.techno-slider-dots li {
  width: 70px;
  padding-top: 3px;
  transition: all 1s;
}
.techno-slider-dots li:not(:last-of-type) {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .techno-slider-dots li:not(:last-of-type) {
    margin-right: 20px;
  }
}
.techno-slider-dots li button {
  display: block;
  width: 100%;
  height: 2px;
  opacity: 0.25;
  background: #ce1518;
  transition: all 1s;
}
.techno-slider-dots .slick-active {
  padding-top: 0;
}
.techno-slider-dots .slick-active button {
  opacity: 1;
  height: 5px;
}
.techno-text-block {
  color: #1a1a1a;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 11px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .techno-text-block {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }
}
.techno-text-block .main-text-wrap {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .techno-text-block .main-text-wrap {
    margin-bottom: 50px;
  }
}
@media (min-width: 1920px) {
  .techno-text-block .main-text-wrap {
    padding-left: 65px;
    max-width: 1300px;
  }
}
.techno-red-ribbon {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #ce1518;
  color: white;
}
@media (min-width: 768px) {
  .techno-red-ribbon {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (min-width: 1920px) {
  .techno-red-ribbon .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.techno-red-ribbon .slogan {
  font-size: 11px;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .techno-red-ribbon .slogan {
    font-size: 22px;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .techno-red-ribbon .slogan {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .techno-red-ribbon .slogan {
    font-size: 22px;
  }
}
.techno-red-ribbon .slogan > * {
  display: inline-block;
  vertical-align: middle;
}
.techno-red-ribbon .slogan .icon {
  flex-grow: 1000;
  font-size: 3.18181818em;
  margin-right: 0.71428571em;
}
.techno-red-ribbon .slogan .text {
  flex-shrink: 1000;
}
.techno-red-ribbon .slogan .text > * {
  display: inline;
  white-space: normal;
}
.techno-red-ribbon .slogan .text .title {
  font-weight: bold;
  font-size: 1em;
  display: block;
  margin: 0 0 0.5em;
}
@media (min-width: 768px) {
  .techno-red-ribbon .slogan .text .title {
    margin: 0;
    display: inline;
    padding-right: 4.54545455em;
    position: relative;
  }
  .techno-red-ribbon .slogan .text .title::after {
    display: block;
    content: "";
    position: absolute;
    width: 1.36363636em;
    right: 1.59090909em;
    top: 50%;
    border-bottom: 1px solid currentColor;
  }
}
@media (min-width: 1920px) {
  .techno-solutions-block .main-text-wrap {
    max-width: 50%;
  }
}
.techno-services-tile {
  background: #1c1d1e;
  color: white;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 8px;
  font-weight: 300;
  position: relative;
  transform: scale(1) translateZ(0);
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  .techno-services-tile {
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3.88888889em;
    padding-right: 5em;
    padding-bottom: 4.44444444em;
  }
}
@media (min-width: 1280px) {
  .techno-services-tile {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .techno-services-tile {
    font-size: 18px;
  }
}
.techno-services-tile .flex-row {
  flex-direction: row-reverse;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .techno-services-tile .flex-row {
    flex-direction: row;
    align-items: center;
  }
}
.techno-services-tile .title {
  text-transform: uppercase;
  font-size: 2.22222222em;
  font-weight: 900;
  margin-top: 0;
  padding-top: 0.5em;
  margin-bottom: 1em;
  border-top: 0.4em solid #ce1518;
}
.techno-services-tile .flex-item .img-wrap {
  width: 150px;
}
@media (min-width: 768px) {
  .techno-services-tile .flex-item .img-wrap {
    width: 130px;
  }
}
@media (min-width: 992px) {
  .techno-services-tile .flex-item .img-wrap {
    width: 280px;
  }
}
@media (min-width: 1280px) {
  .techno-services-tile .flex-item .img-wrap {
    width: 130px;
  }
}
@media (min-width: 1920px) {
  .techno-services-tile .flex-item .img-wrap {
    width: 280px;
  }
}
.techno-services-tile .flex-item:first-child {
  flex-grow: 1000;
  flex-shrink: 1;
}
.techno-services-tile .flex-item:last-child {
  flex-grow: 1;
  flex-shrink: 1000;
}
.techno-services-tile .text-wrap {
  position: relative;
  font-size: 1.25em;
  padding-top: 1em;
  padding-bottom: 2.5em;
  margin-right: -20px;
}
@media (min-width: 768px) {
  .techno-services-tile .text-wrap {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1em;
    padding-left: 40px;
    margin-left: 15px;
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .techno-services-tile .text-wrap {
    padding-left: 30px;
    margin-left: 0px;
  }
}
@media (min-width: 1920px) {
  .techno-services-tile .text-wrap {
    padding-left: 40px;
    margin-left: 15px;
  }
}
@media (min-width: 768px) {
  .techno-services-tile .text-wrap::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    border-left: 1px solid #ce1518;
    height: 150px;
    margin-top: -75px;
  }
}
@media (min-width: 992px) {
  .techno-services-tile .text-wrap::before {
    height: 200px;
    margin-top: -100px;
  }
}
@media (min-width: 1280px) {
  .techno-services-tile .text-wrap::before {
    height: 150px;
    margin-top: -75px;
  }
}
@media (min-width: 1920px) {
  .techno-services-tile .text-wrap::before {
    height: 200px;
    margin-top: -100px;
  }
}
.techno-services-tile .link-wrap {
  position: absolute;
  left: 2.22222222em;
  bottom: 1.11111111em;
}
@media (min-width: 768px) {
  .techno-services-tile .link-wrap {
    left: auto;
    right: 2.22222222em;
  }
}
.techno-services-tile .common-btn {
  display: block;
  width: 3.33333333em;
  height: 3.33333333em;
  background: #ce1518;
  border-color: #ce1518;
}
.techno-services-tile .common-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.88888889em;
  height: 0.88888889em;
  margin-top: -0.44444444em;
  margin-left: -0.66666667em;
  border-top: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
}
@media (max-width: 767px) {
  .techno-services-row > * {
    margin-bottom: 15px!important;
  }
}
.techno-services-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #1a1a1a;
}
@media (min-width: 1920px) {
  .techno-services-block .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.techno-news-tile {
  display: block;
  position: relative;
  transform: scale(1) translateZ(0);
}
.techno-news-tile .img-wrap {
  background: #5d5d5d;
  display: block;
}
.techno-news-tile .img-wrap img {
  opacity: 0.3;
  transition: transform 0.25s;
}
.techno-news-tile .content-wrap {
  padding: 10px 0 0;
  font-size: 11px;
  display: block;
}
@media (min-width: 768px) {
  .techno-news-tile .content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 120px 30px 30px;
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .techno-news-tile .content-wrap {
    font-size: 14px;
    padding: 80px 30px 30px;
  }
}
@media (min-width: 1920px) {
  .techno-news-tile .content-wrap {
    font-size: 18px;
    padding: 120px 30px 30px;
  }
}
.techno-news-tile .content-wrap .date {
  display: block;
  font-weight: 300;
  font-size: 1em;
  margin-bottom: 1.22222222em;
  text-shadow: 0 0 1px black, 0 0 5px black;
}
@media (max-width: 767px) {
  .techno-news-tile .content-wrap .date {
    position: absolute;
    top: 5.66666667em;
    left: 1.66666667em;
  }
}
.techno-news-tile .content-wrap .title {
  display: block;
  margin: 0;
  font-size: 1.22222222em;
  line-height: 1.36363636em;
}
@media (max-width: 767px) {
  .techno-news-tile .content-wrap .title {
    color: #1a1a1a;
  }
}
@media (min-width: 768px) {
  .techno-news-tile .content-wrap .title {
    text-shadow: 0 0 1px black, 0 0 5px black;
  }
}
.techno-news-tile .content-wrap .title span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: border 0.25s;
}
.techno-news-tile .content-wrap .title:hover span {
  border-color: transparent;
}
.techno-news-tile .content-wrap .label {
  position: absolute;
  left: 0;
  top: 2em;
  background: #161616;
  padding: 0.55555556em 1.66666667em;
  font-weight: 300;
}
.techno-news-tile .content-wrap .label.red {
  background: #ce1518;
}
.techno-news-tile:hover .img-wrap img {
  transform: scale(1.4);
  transition: transform 2.5s;
}
.techno-news-row {
  width: calc(100% + 14px);
  margin-left: -7px;
  margin-right: -7px;
}
.techno-news-row > * {
  padding-left: 7px;
  padding-right: 7px;
}
@media (min-width: 768px) {
  .techno-news-row {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
  .techno-news-row > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.techno-news-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #1a1a1a;
}
@media (min-width: 1920px) {
  .techno-news-block .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.techno-news-block a {
  color: white;
  text-decoration: none;
}
.techno-news-block a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.techno-news-block a:focus {
  color: white;
  text-decoration: none;
}
.techno-news-block a:active,
.techno-news-block a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.techno-fos-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #1a1a1a;
  background: #f0f2f5;
}
.techno-fos-block .main-text {
  font-size: 22px;
}
@media (max-width: 767px) {
  .techno-fos-block .main-text {
    margin-bottom: 20px!important;
  }
}
@media (min-width: 768px) {
  .techno-fos-block .main-text {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .techno-fos-block .main-text {
    font-size: 48px;
    margin-right: -100px;
  }
}
@media (min-width: 1280px) {
  .techno-fos-block .main-text {
    font-size: 50px;
    margin-right: 0;
  }
}
@media (min-width: 1920px) {
  .techno-fos-block .main-text {
    font-size: 70px;
  }
}
@media (min-width: 1920px) {
  .techno-fos-block .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
@media (min-width: 1280px) {
  .techno-fos-block form {
    padding-right: 30px;
  }
}
.techno-fos-block .img-wrap {
  position: relative;
  margin-left: -15px;
  margin-bottom: -30px;
}
@media (min-width: 1280px) {
  .techno-fos-block .img-wrap {
    margin-left: -30px;
  }
}
.techno-fos-block .img-wrap .techno-man-text {
  position: absolute;
  right: 0;
  top: 50%;
  width: 190px;
  text-shadow: 0 0 1px white, 0 0 2px white;
  font-size: 16px;
}
@media (min-width: 992px) {
  .techno-fos-block .img-wrap .techno-man-text {
    left: 60px;
    top: 30px;
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .techno-fos-block .img-wrap .techno-man-text {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .techno-fos-block .img-wrap .techno-man-text {
    left: auto;
    right: 0;
    top: 50%;
  }
}
.techno-fos-block .img-wrap .techno-man-text .name {
  font-size: 1.25em;
  font-weight: normal;
}
.techno-fos-block .img-wrap .techno-man-text .post {
  font-weight: bold;
}
.techno-fos-block .img-wrap .techno-man-text hr {
  border-color: #ce1518;
  width: 85px;
  margin-left: 0;
}
.techno-vacancies-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #1a1a1a;
}
@media (min-width: 1920px) {
  .techno-vacancies-block .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.techno-vacancies-block .vacancies-tile {
  background: #f6f6f6;
  padding: 30px;
}
.techno-contacts-block {
  padding-top: 30px;
  color: #1a1a1a;
}
@media (min-width: 1920px) {
  .techno-contacts-block .main-text-wrap {
    padding-left: 65px;
    padding-right: 65px;
  }
}
.techno-contacts-block .phone-email {
  font-size: 16px;
}
@media (min-width: 768px) {
  .techno-contacts-block .phone-email {
    text-align: right;
  }
}
.techno-contacts-block .phone-email .email {
  font-size: 1em;
  font-weight: 300;
}
.techno-contacts-block .phone-email .email a {
  color: #ce1518;
  text-decoration: none;
}
.techno-contacts-block .phone-email .email a:hover {
  color: #b71315;
  text-decoration: none;
}
.techno-contacts-block .phone-email .email a:focus {
  color: #ce1518;
  text-decoration: none;
}
.techno-contacts-block .phone-email .email a:active,
.techno-contacts-block .phone-email .email a:active:focus {
  color: #a01013;
  text-decoration: none;
}
.techno-contacts-block .phone-email .email a span {
  border-bottom: 1px solid rgba(206, 21, 24, 0.5);
}
.techno-contacts-block .phone-email .email a:hover span {
  border-color: transparent;
}
.techno-contacts-block .phone-email .phone {
  font-size: 1.875em;
  font-weight: 900;
}
.techno-contacts-block .phone-email .phone a {
  color: #1a1a1a;
  text-decoration: none;
}
.techno-contacts-block .phone-email .phone a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.techno-contacts-block .phone-email .phone a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.techno-contacts-block .phone-email .phone a:active,
.techno-contacts-block .phone-email .phone a:active:focus {
  color: #000000;
  text-decoration: none;
}
.mini-map-address {
  position: relative;
  padding-left: 60px;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.mini-map-address .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 2.875em;
  color: #ce1518;
}
.mini-map-address .city {
  font-weight: bold;
  font-size: 1.25em;
}
.mini-map-address::after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 260px;
  border-bottom: 6px solid #ce1518;
}
.news-tile {
  margin-bottom: 30px;
  background-color: #e6e6e6;
  padding: 15px;
}
@media (min-width: 1200px) {
  .news-tile {
    padding: 30px;
  }
}
.news-tile .img-wrap {
  position: relative;
  overflow: visible;
}
.news-tile .img-wrap .date {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  text-align: center;
  background-color: #b8b8b8;
  transition: background 0.25s;
  color: white;
  padding: 6px 0;
  font-size: 10px;
}
@media (min-width: 1200px) {
  .news-tile .img-wrap .date {
    left: -30px;
    top: -30px;
  }
}
.news-tile .img-wrap .date span.big {
  font-size: 2.4em;
  font-weight: bold;
  display: block;
}
.news-tile .img-wrap .date span.small {
  font-size: 1em;
  line-height: 1.1;
  display: inline-block;
  vertical-align: middle;
}
.news-tile .title {
  font-size: 13px;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  max-height: 3em;
  overflow: hidden;
}
@media (min-width: 768px) {
  .news-tile .title {
    font-size: 16px;
  }
}
.news-tile .announce {
  max-height: 5.71428571em;
  overflow: hidden;
  font-size: 12px;
}
@media (min-width: 768px) {
  .news-tile .announce {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .news-tile .announce {
    max-height: 4.28571429em;
  }
}
.news-tile .text-wrap {
  height: 126px;
}
@media (min-width: 1200px) {
  .news-tile .text-wrap {
    padding-right: 50px;
  }
}
.news-page-date {
  background: #ce1518;
  color: white;
  display: inline-block;
  padding: 5px 20px 3px;
}
.small-news-tile .text-wrap {
  height: initial;
  padding-top: 60px;
}
.small-news-tile .w-image {
  padding-top: 0;
}
@media (min-width: 768px) {
  .small-news-tile .w-image {
    padding-left: 145px;
    height: 130px;
    padding-top: 30px;
  }
}
@media (min-width: 1200px) {
  .small-news-tile .w-image {
    padding-left: 160px;
  }
}
@media (min-width: 768px) {
  .small-news-tile .w-image .img-wrap {
    position: absolute;
    top: 30px;
    left: 15px;
  }
}
@media (min-width: 1200px) {
  .small-news-tile .w-image .img-wrap {
    left: 30px;
  }
}
.small-news-tile .w-image .title {
  margin-top: 0;
  max-height: initial;
  overflow: initial;
}
@media (min-width: 768px) {
  .small-news-tile .w-image .title {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .small-news-tile .w-image .title {
    font-size: 16px;
  }
}
.small-news-tile .date {
  position: absolute;
  top: 0;
  left: 0;
  background: #b8b8b8;
  transition: background 0.25s;
  color: white;
  padding: 10px 20px;
  font-size: 10px;
}
.small-news-tile .date span.big {
  font-size: 2.4em;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.small-news-tile .date span.small {
  font-size: 1em;
  line-height: 1.1;
  display: inline-block;
  vertical-align: middle;
}
.small-news-tile.hover .date,
.small-news-tile:hover .date {
  background-color: #ce1518;
}
.tile-data {
  font-size: 16px;
  position: relative;
  z-index: 5;
}
.tile-data .icon {
  color: #b8b8b8;
  font-size: 1.5em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3em;
  transition: color 0.25s;
}
.tile-data .text {
  display: inline-block;
  vertical-align: middle;
  color: #1a1a1a;
  line-height: 1;
  border-bottom: 1px solid rgba(26, 26, 26, 0.5);
}
.tile-data .comments,
.tile-data .likes {
  cursor: pointer;
  white-space: nowrap;
}
.tile-data .comments:not(:last-child),
.tile-data .likes:not(:last-child) {
  margin-right: 0.75em;
}
@media (min-width: 768px) {
  .tile-data .comments:not(:last-child),
  .tile-data .likes:not(:last-child) {
    margin-right: 1.5em;
  }
}
.tile-social-links .social-icon {
  background-color: #b8b8b8;
  position: relative;
  overflow: visible;
}
.tile-social-links .social-icon img {
  position: relative;
}
.tile-social-links .social-icon::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: all 0.25s;
}
.tile-social-links .social-icon:hover::before {
  top: -5px;
  bottom: -15px;
  background-color: #ce1518;
}
.tile-social-links .social-list {
  position: absolute;
  right: 10px;
  bottom: 15px;
}
@media (min-width: 1200px) {
  .tile-social-links .social-list {
    right: 25px;
  }
}
@media (min-width: 768px) {
  .news-list .flex-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1920px) {
  .news-list .flex-item {
    width: calc(100% / 3);
  }
}
@media (min-width: 1920px) {
  .main-news-list .flex-item.hidden-lg {
    display: block !important;
  }
}
.news-block {
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .news-block {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) {
  .news-block .main-text-wrap:not(.wide-text) {
    max-width: 600px;
  }
}
@media (min-width: 1280px) {
  .news-block .main-text-wrap:not(.wide-text) {
    max-width: 980px;
  }
}
@media (min-width: 1920px) {
  .news-block .main-text-wrap:not(.wide-text) {
    max-width: 1000px;
  }
}
@media (min-width: 1920px) {
  .news-block .main-text-wrap {
    padding-left: 65px;
  }
}
.news-page {
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .news-page {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.news-page .aside-title {
  margin-top: 0;
}
.news-page .header-bar {
  background-image: url(../img/bg/header_bar_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 30px;
  min-height: 100px;
}
@media (min-width: 768px) {
  .news-page .header-bar {
    min-height: 150px;
    padding-top: 20px;
  }
  .news-page .header-bar .breadcrumbs {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .news-page .header-bar {
    min-height: 200px;
  }
}
.news-page .header-bar .main-text {
  font-weight: 900;
}
.date-label {
  display: inline-block;
  background: #ce1518;
  color: white;
  font-weight: 300;
  font-size: 16px;
  padding: 5px 20px 3px;
}
.article-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .article-title {
    font-size: 36px;
  }
}
.news-article {
  font-size: 14px;
}
@media (min-width: 768px) {
  .news-article {
    font-size: 18px;
  }
}
.clients-block {
  padding-top: 20px;
  padding-bottom: 10px;
  color: white;
  background-image: url(../img/bg/clients_bg.jpg);
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  .clients-block {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
.clients-block .description {
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .clients-block .description {
    margin-bottom: 60px;
    font-size: 18px;
  }
}
.clients-page {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .clients-page {
    padding-top: 20px;
  }
}
.clients-logo-slider {
  margin: -15px;
}
.clients-logo-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}
.clients-logo-slider .img-wrap {
  display: block!important;
  transition: transform 0.25s;
}
.clients-logo-slider .img-wrap:hover {
  transform: scale(1.1);
}
.clients-logo-slider .slick-list {
  padding-top: 15px;
  padding-bottom: 15px;
}
.clients-logo-slider .clients-dots {
  position: absolute;
  left: 15px;
  width: calc(100vw - 30px);
  display: flex;
  bottom: 10px;
}
@media (min-width: 768px) {
  .clients-logo-slider .clients-dots {
    bottom: 0;
  }
}
.clients-logo-slider .clients-dots li {
  width: 70px;
  padding-top: 3px;
  transition: all 1s;
}
.clients-logo-slider .clients-dots li:not(:last-of-type) {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .clients-logo-slider .clients-dots li:not(:last-of-type) {
    margin-right: 20px;
  }
}
.clients-logo-slider .clients-dots li button {
  display: block;
  width: 100%;
  height: 2px;
  opacity: 0.25;
  background: white;
  transition: all 1s;
}
.clients-logo-slider .clients-dots .slick-active {
  padding-top: 0;
}
.clients-logo-slider .clients-dots .slick-active button {
  opacity: 1;
  height: 5px;
}
.clients-row .flex-item {
  width: calc(100% / 2);
}
@media (min-width: 768px) {
  .clients-row .flex-item {
    width: calc(100% / 3);
  }
}
@media (min-width: 992px) {
  .clients-row .flex-item {
    width: calc(100% / 4);
  }
}
@media (min-width: 1280px) {
  .clients-row .flex-item {
    width: calc(100% / 5);
  }
}
@media (min-width: 1920px) {
  .clients-row .flex-item {
    width: calc(100% / 7);
  }
  .clients-row .flex-item.hidden-lg {
    display: block!important;
  }
}
.clients-row .all-clients-tile {
  display: block;
  padding-bottom: 100%;
  position: relative;
  transition: all 0.25s;
  background: #ce1518;
  color: white;
}
.clients-row .all-clients-tile a {
  color: white;
  text-decoration: none;
}
.clients-row .all-clients-tile a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.clients-row .all-clients-tile a:focus {
  color: white;
  text-decoration: none;
}
.clients-row .all-clients-tile a:active,
.clients-row .all-clients-tile a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.clients-row .all-clients-tile::after {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  border: 4px solid white;
}
@media (min-width: 768px) {
  .clients-row .all-clients-tile::after {
    top: 32px;
    left: 32px;
    bottom: 32px;
    right: 32px;
    border: 6px solid white;
  }
}
.clients-row .all-clients-tile .text {
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 25px;
  right: 25px;
  transform: translateY(-50%);
  font-size: 14px;
}
@media (min-width: 768px) {
  .clients-row .all-clients-tile .text {
    font-size: 25px;
    left: 52px;
    right: 52px;
  }
}
.clients-row .all-clients-tile:hover {
  transform: scale(1.1);
  box-shadow: 0 35px 28px #0e1115;
}
.clients-row .client-logo-tile {
  display: block;
  padding-bottom: 100%;
  position: relative;
  transition: all 0.25s;
}
.clients-row .client-logo-tile::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid rgba(255, 255, 255, 0.15);
}
@media (min-width: 768px) {
  .clients-row .client-logo-tile::after {
    border: 16px solid rgba(255, 255, 255, 0.15);
  }
}
.clients-row .client-logo-tile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clients-row .client-logo-tile .front {
  background: white;
  opacity: 0;
  transition: opacity 0.25s;
}
.clients-row .client-logo-tile:hover {
  transform: scale(1.1);
  box-shadow: 0 35px 28px #0e1115;
}
.clients-row .client-logo-tile:hover .front {
  opacity: 1;
}
.vacancies-tile {
  margin-bottom: 30px;
  position: relative;
}
.vacancies-tile a {
  color: #1a1a1a;
  text-decoration: none;
}
.vacancies-tile a:hover {
  color: #0d0d0d;
  text-decoration: none;
}
.vacancies-tile a:focus {
  color: #1a1a1a;
  text-decoration: none;
}
.vacancies-tile a:active,
.vacancies-tile a:active:focus {
  color: #000000;
  text-decoration: none;
}
.vacancies-tile span.metro {
  display: inline-block;
  vertical-align: 0;
  margin-right: 5px;
  width: 24px;
  height: 24px;
  background: #ce1518;
  color: white;
  border-radius: 50%;
  line-height: 26px;
  text-align: center;
  margin-top: 5px;
}
.vacancies-tile .title,
.vacancies-tile .salary {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px;
}
.vacancies-tile .title {
  padding-right: 30px;
}
.vacancies-tile .title span {
  transition: all 0.25s;
  display: inline-block;
  border-bottom: 1px solid rgba(26, 26, 26, 0.25);
}
.vacancies-tile .title a:hover span {
  border-color: transparent!important;
}
.vacancies-tile .description {
  margin-bottom: 5px;
}
.vacancies-tile .date {
  color: #9a9a9a;
}
.vacancies-tile .favorite-checker {
  position: absolute;
  top: 25px;
  right: 30px;
}
.vacancies-tile-wrap {
  background: #f6f6f6;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.25s;
}
.vacancies-tile-wrap:hover {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
}
@media (min-width: 768px) {
  .vacancies-tile-wrap {
    padding: 30px;
    margin-bottom: 30px;
  }
}
.favorite-checker svg {
  font-size: 24px;
}
.favorite-checker svg:nth-of-type(1) {
  color: #9a9a9a;
}
.favorite-checker svg:nth-of-type(2) {
  color: #ce1518;
  display: none;
}
.favorite-checker input:checked ~ svg:nth-of-type(1) {
  display: none;
}
.favorite-checker input:checked ~ svg:nth-of-type(2) {
  display: block;
}
.services-page {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url(../img/bg/services_bg.jpg);
  background-position: center top;
  background-size: cover;
}
@media (min-width: 768px) {
  .services-page {
    padding-top: 30px;
    padding-bottom: 100px;
  }
}
.services-page .main-text-wrap {
  font-weight: 200;
  transform: scale(1) translateZ(0);
  font-size: 16px;
}
@media (min-width: 1920px) {
  .services-page .main-text-wrap {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (min-width: 768px) {
  .services-page .main-text-wrap {
    font-size: 20px;
    padding-top: 20px;
  }
}
.services-page .main-text-wrap p {
  line-height: 1.2;
  margin-bottom: 2em;
}
.services-page .sphere-solutions-block {
  background: none;
  padding: 0;
}
.tags-row {
  margin: 0;
  font-size: 1.5em;
  line-height: 0.6;
  color: #b8b8b8;
  transition: all 0.25s;
}
.service-tile {
  transform: scale(1) translateZ(0);
  background: #f6f6f6;
  color: #1a1a1a;
  height: 100%;
  line-height: 1.2;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  transition: all 0.25s;
  font-size: 14px;
  padding: 15px;
}
@media (min-width: 768px) {
  .service-tile {
    font-size: 20px;
    padding: 30px;
  }
}
@media (min-width: 992px) {
  .service-tile {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .service-tile {
    font-size: 20px;
    min-height: 405px;
  }
}
.service-tile .flex-item:first-child {
  flex-shrink: 1;
  flex-grow: 1000;
}
.service-tile .flex-item:last-child {
  flex-shrink: 1000;
  flex-grow: 1;
}
.service-tile .flex-row {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .service-tile .flex-row {
    flex-wrap: nowrap;
  }
}
.service-tile .img-wrap {
  text-align: left;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .service-tile .img-wrap {
    margin-bottom: 0;
  }
}
.service-tile .title {
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: 1;
  font-weight: normal;
  padding-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-left: 2px solid #ce1518;
  margin: 0 0 1em;
  transition: border 0.25s;
}
.service-tile .text-wrap {
  padding-bottom: 65px;
}
.service-tile .tags-row {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.service-tile .service-more-btn-wrap {
  position: absolute;
  bottom: 30px;
  right: 30px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s;
}
.service-tile:hover {
  background: #ce1518;
  color: white;
}
.service-tile:hover .tags-row {
  color: #ce1518;
  pointer-events: none;
}
.service-tile:hover .service-more-btn-wrap {
  opacity: 1;
  pointer-events: auto;
}
.service-tile:hover .title {
  border-color: white;
}
.services-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
.services-grid .grid-item {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .services-grid .grid-item {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .services-grid .big-item {
    width: 100%;
  }
  .services-grid .medium-item {
    width: 50%;
  }
  .services-grid .small-item {
    width: 50%;
  }
}
@media (min-width: 1920px) {
  .services-grid .big-item {
    width: 66.66666667%;
  }
  .services-grid .medium-item {
    width: 50%;
  }
  .services-grid .small-item {
    width: 33.33333333%;
  }
}
@media (min-width: 1920px) {
  .services-grid-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.svg-map {
  margin: -24% -25% -26%;
  position: relative;
}
@media (min-width: 1280px) {
  .svg-map {
    margin-bottom: -16%;
  }
}
@media (min-width: 1600px) {
  .svg-map {
    margin-bottom: -15%;
  }
}
@media (min-width: 1920px) {
  .svg-map {
    margin-bottom: -18%;
  }
}
.svg-map path {
  fill: #a7a9ac;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  cursor: pointer;
  transition: fill 0.25s;
}
.svg-map path:hover,
.svg-map path.active {
  fill: #ce1518;
}
.accordion {
  position: relative;
  z-index: 1;
}
.accordion div {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 55px;
}
@media (min-width: 992px) {
  .accordion div {
    padding-left: 55px;
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .accordion div {
    padding-left: 75px;
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  .accordion div {
    padding-left: 95px;
  }
}
.accordion h3 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .accordion h3 {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  .accordion h3 {
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .accordion h3 {
    font-size: 20px;
  }
}
.accordion h3::before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #dcdcdc;
  margin-right: 15px;
  transition: background 0.25s;
}
.accordion h3 span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 25px;
  white-space: normal;
  width: calc(100% - 50px);
}
@media (min-width: 768px) {
  .accordion h3 span {
    width: initial;
    max-width: calc(100% - 50px);
  }
}
.accordion h3 span::after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  transition: all 0.25s;
}
.accordion h3.active {
  font-weight: bold;
}
.accordion h3.active::before {
  background: #ce1518;
}
.accordion h3.active span::after {
  transform: scale(1, -1) rotate(45deg);
  margin-top: -1px;
}
.accordion h3 + div {
  display: none;
}
.accordion div:first-of-type {
  display: block;
}
.geography-page {
  overflow: hidden;
}
.geography-page .slogan {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 30px;
}
@media (min-width: 1280px) {
  .geography-page .slogan {
    margin-right: calc(635px - 50vw);
  }
}
@media (min-width: 1600px) {
  .geography-page .slogan {
    margin-right: calc(635px - 50vw);
    padding-left: 60px;
  }
}
@media (min-width: 1920px) {
  .geography-page .slogan {
    padding-left: 130px;
    margin-right: calc(925px - 50vw);
  }
}
.geography-page .slogan .flex-row {
  font-size: 36px;
  line-height: 1.2;
  background: #282c34;
  padding: 1em 1.5em;
  align-items: center;
}
@media (min-width: 1280px) {
  .geography-page .slogan .flex-row {
    font-size: 24px;
  }
}
@media (min-width: 1600px) {
  .geography-page .slogan .flex-row {
    font-size: 26px;
  }
}
@media (min-width: 1920px) {
  .geography-page .slogan .flex-row {
    font-size: 36px;
  }
}
.geography-page .slogan .flex-row .icon {
  font-size: 1.66666667em;
}
.geography-page .slogan .text {
  margin-left: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 60px;
}
@media (min-width: 1280px) {
  .geography-page .slogan .text {
    max-width: 500px;
  }
}
@media (min-width: 1600px) {
  .geography-page .slogan .text {
    max-width: 500px;
  }
}
@media (min-width: 1920px) {
  .geography-page .slogan .text {
    max-width: 700px;
  }
}
.contacts-page {
  background-image: url(../img/bg/contacts_bg.jpg);
  background-size: cover;
  background-position: center top;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 1920px) {
  .contacts-page .xl-wrap {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.contacts-row > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
@media (min-width: 768px) {
  .contacts-row > * {
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .contacts-row > * {
    margin-right: 50px;
  }
}
.contacts-row .address {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .contacts-row .address {
    font-size: 24px;
  }
}
.contacts-row .address .ttu {
  font-size: 1.25em;
  font-weight: bold;
}
.contacts-row .phone-email {
  position: relative;
  padding-left: 70px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .contacts-row .phone-email {
    padding-left: 60px;
  }
  .contacts-row .phone-email::before {
    display: block;
    content: "";
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    transform: rotate(30deg);
    height: 80px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -40px;
  }
}
.contacts-row .phone-email a {
  color: white;
  text-decoration: none;
}
.contacts-row .phone-email a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.contacts-row .phone-email a:focus {
  color: white;
  text-decoration: none;
}
.contacts-row .phone-email a:active,
.contacts-row .phone-email a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.contacts-row .phone-email > * {
  display: block;
}
@media (min-width: 768px) {
  .contacts-row .phone-email {
    font-size: 24px;
  }
}
.contacts-row .phone-email .phone {
  font-size: 1.25em;
  font-weight: bold;
}
.contacts-row .phone-email .email .text {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.25s;
  line-height: 1;
  display: inline-block;
}
.contacts-row .phone-email .email:hover .text {
  border-color: transparent;
}
.form-wrap {
  background: rgba(35, 38, 43, 0.63);
  padding-top: 40px;
  padding-bottom: 30px;
}
.contacts-form {
  max-width: 925px;
}
@media (min-width: 768px) {
  .manager-tile {
    position: relative;
    padding-left: 180px;
  }
}
@media (min-width: 768px) {
  .manager-tile {
    padding-left: 135px;
  }
}
@media (min-width: 1280px) {
  .manager-tile {
    padding-left: 180px;
  }
}
@media (min-width: 1920px) {
  .manager-tile {
    padding-left: 250px;
  }
}
@media (min-width: 768px) {
  .manager-tile .img-wrap {
    width: 120px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (min-width: 1280px) {
  .manager-tile .img-wrap {
    width: 150px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .img-wrap {
    width: 210px;
  }
}
@media (min-width: 768px) {
  .manager-tile .content {
    height: 120px;
    padding-left: 15px;
    border-left: 1px solid white;
  }
}
@media (min-width: 1280px) {
  .manager-tile .content {
    height: 150px;
    padding-left: 30px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .content {
    height: 210px;
  }
}
.manager-tile .content .name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .manager-tile .content .name {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .manager-tile .content .name {
    font-size: 20px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .content .name {
    font-size: 24px;
  }
}
.manager-tile .content .post {
  font-size: 14px;
  font-weight: normal;
}
@media (min-width: 768px) {
  .manager-tile .content .post {
    font-size: 12px;
  }
}
@media (min-width: 1280px) {
  .manager-tile .content .post {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .content .post {
    font-size: 18px;
  }
}
.manager-tile .content a {
  color: white;
  text-decoration: none;
}
.manager-tile .content a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.manager-tile .content a:focus {
  color: white;
  text-decoration: none;
}
.manager-tile .content a:active,
.manager-tile .content a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.manager-tile .content .phone {
  display: block;
}
.manager-tile .content .phone .text {
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 1280px) {
  .manager-tile .content .phone .text {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .content .phone .text {
    font-size: 18px;
  }
}
.manager-tile .content .email {
  display: block;
}
.manager-tile .content .email .text {
  font-size: 14px;
  font-weight: 300;
}
@media (min-width: 1280px) {
  .manager-tile .content .email .text {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  .manager-tile .content .email .text {
    font-size: 18px;
  }
}
.manager-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.manager-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
}
.search-filter {
  padding: 0 15px;
}
.search-filter fieldset {
  padding: 0;
  border: none;
  margin: 0;
}
.search-filter legend {
  font-size: 16px;
  max-width: 300px;
}
.search-filter label {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  margin-right: 15px;
}
@media (min-width: 992px) {
  .search-filter label {
    display: block;
    margin-right: 0;
  }
}
.search-filter label input:checked + span {
  font-weight: bold;
  color: #ce1518;
}
@media (min-width: 1600px) {
  .search-block .aside-layout,
  .search-block .search-input-wrap {
    max-width: 1200px;
  }
}
@media (min-width: 1920px) {
  .search-block .xl-wrap {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.search-tile {
  font-size: 14px;
  position: relative;
}
@media (min-width: 768px) {
  .search-tile {
    padding-left: 160px;
    padding-right: 160px;
    min-height: 130px;
  }
}
@media (min-width: 768px) {
  .search-tile .img-wrap {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.search-tile .date {
  position: absolute;
  top: 0;
  right: 0;
  background: #b8b8b8;
  transition: background 0.25s;
  color: white;
  padding: 3px 10px;
  font-size: 8px;
}
@media (min-width: 768px) {
  .search-tile .date {
    padding: 10px 20px;
    font-size: 10px;
  }
}
.search-tile .date span.big {
  font-size: 2.4em;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.search-tile .date span.small {
  font-size: 1em;
  line-height: 1.1;
  display: inline-block;
  vertical-align: middle;
}
.search-tile.hover .date,
.search-tile:hover .date {
  background-color: #ce1518;
}
.search-tile .label {
  text-transform: uppercase;
  font-size: 1.14285714em;
  color: #b2b2b2;
}
.search-tile .h3 {
  font-size: 1.14285714em;
  font-weight: 400;
}
.search-tile .title {
  text-transform: uppercase;
}
.search-tile:hover .comments .icon,
.search-tile:hover .likes .icon {
  color: #ce1518;
}
.search-input-wrap {
  position: relative;
}
.search-input-wrap .reset-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 30px;
  transform: translateY(-50%);
  margin-top: 0.05em;
}
.search-input-wrap .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #1a1a1a;
  box-shadow: none;
  font-size: 20px;
  padding-left: 0;
  padding-right: 30px;
}
.page-not-found {
  background-image: url(../img/bg/not_found_bg.jpg);
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 234px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 540px) {
  .page-not-found {
    min-height: calc(100vh - 103px);
  }
}
@media (min-width: 768px) {
  .page-not-found {
    min-height: calc(100vh - 395px);
  }
}
@media (min-width: 992px) {
  .page-not-found {
    min-height: calc(100vh - 375px);
  }
}
.page-not-found a {
  color: white;
  text-decoration: none;
}
.page-not-found a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.page-not-found a:focus {
  color: white;
  text-decoration: none;
}
.page-not-found a:active,
.page-not-found a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.page-not-found .content-box {
  width: 100%;
  color: white;
  font-weight: 300;
  padding: 30px 15px;
}
@media (min-width: 768px) {
  .page-not-found .content-box {
    max-width: 720px;
    padding: 30px 0 60px;
  }
}
@media (min-width: 992px) {
  .page-not-found .content-box {
    max-width: 720px;
  }
}
@media (min-width: 1280px) {
  .page-not-found .content-box {
    max-width: 1080px;
  }
}
.page-not-found .title {
  text-transform: uppercase;
  line-height: 1;
  font-size: 60px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .page-not-found .title {
    font-size: 150px;
  }
}
.page-not-found .subtitle {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}
@media (min-width: 768px) {
  .page-not-found .subtitle {
    font-size: 40px;
  }
}
.page-not-found .description {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 37px;
  line-height: 1.2;
  max-width: 500px;
}
@media (min-width: 768px) {
  .page-not-found .description {
    margin-bottom: 75px;
    font-size: 22px;
  }
}
@media (max-width: 479px) {
  .page-not-found .common-btn {
    display: block;
  }
}
