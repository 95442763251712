.footer {
  background: #3d4045;
  padding-top: 17px;
  padding-bottom: 17px;
  .social-icon {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #1c1d1e;
    transition: background .25s;
    &::before {
      content: "";
      line-height: 38px;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
    }
    &:hover {
      background-color: @red;
    }
  }
  @media (min-width: 768px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .logo {
    width: 32px;
    @media (min-width: 768px) {
      width: 53px;
    }
  }
  .logo-row {
    margin-bottom: 15px;
    @media (min-width: 768px) {
      margin-bottom: 30px;
    }
  }

  .main-flex-item {
    width: 100%;
    @media (min-width: 768px) {
      width: initial;
    }
    &.contact-row {
      @media (min-width: 768px) {
        width: 260px;
      }
      @media (min-width: 1280px) {
        width: 500px;
        .flex-wrap {
          flex-wrap: nowrap;
        }
      }
    }
  }
  .search {
    border-bottom: 1px solid white;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
    input {
      font-size: 16px;
      border: 0;
      //border-bottom: 1px solid white;
      width: 85px;
      transition: width .25s;
      padding: 5px 10px 5px 0;
      color: white;
      background: transparent;

      &::-webkit-input-placeholder {
        color: white;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: white;
        opacity: 1;
      }
      &:focus {
        width: 135px;
        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }
      }

    }
    .icon {
      font-size: 30px;
      color: white;
    }
  }

  .link-colored(white, none);

  .footer-link {
    font-size: 12px;
    border-bottom: 1px solid fade(white, 50%);
    &:hover {
      border-color: transparent;
    }
  }

  .search-col {
    max-width: 180px;
    > * {
      float: right;
    }
  }

  .contact-col {
    max-width: 180px;
    > * {
      float: right;
    }
    .phone {
      font-size: 24px;
      font-weight: bold;

    }
    .email {

      .footer-link {
        font-size: 15px;
      }

    }
  }



  .logo {
    display: inline-block;
    margin-right: 20px;
  }

  .copyright {

    color: white;
    font-size: 14px;
    text-transform: uppercase;
    .c {
      font-size: .9em;
      display: inline-block;
      vertical-align: .25em;
      margin-right: .5em;
    }
  }

  .social-list ~ .footer-link {
    margin-top: 5px;

    @media (min-width: 768px) {
      margin-top: 30px;
    }
  }
  .social-list {
    //text-align: center;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      //text-align: left;
      margin-bottom: 0;
    }
    ~ .copyright {
      //text-align: center;
    }

  }
  .social-list-wrap {
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
}


.sitemap {
  background: white;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 32px;
  }
  .link-colored(@black_text, none);
  a {
    font-size: 15px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
    border-bottom: 1px solid transparent;
    &:hover {
      border-color: fade(@black_text, 50%);
    }
  }

  li {
    padding-left: 7px;
    padding-right: 7px;
    &.active {
      a {
        border-bottom: 2px solid @red;
      }
    }
  }

  ul.list-unstyled {
    max-width: 1140px;
    width: calc(~"100% + 14px");
    margin-left: -7px;
    margin-right: -7px;
  }
}

.contact-col-wrap,
.recall-btn-warp {
  width: 100%;
  text-align: right;
  > * {
    display: inline-block;
  }
  @media (min-width: 1280px) {
    //max-width: 50%!important;
    > * {
      display: block;
    }
  }
}

.contact-col-wrap {
  @media (min-width: 1280px) {
    max-width: 210px!important;
  }

}


.desktop .footer .phone {
  pointer-events: none;
}
